/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/jsx-one-expression-per-line */
// Pdf Components
import React from "react";
import ReactDOM from "react-dom";
import { OP_SYSTEM, ONTARIO, STANDARD, SIZES } from "../../../services/Constant";

const { detect } = require("detect-browser");

const browser = detect();
class PdfMoz extends React.Component {
  constructor(props) {
    super(props);
    this.lightbar = React.createRef();
    this.title = React.createRef();
    this.lights = React.createRef();
    this.warningMessage = React.createRef();
  }

  componentDidMount() {
    const { setLightBarHeight } = this.props;
    const { setLightsHeight } = this.props;
    const { setWarningHeight } = this.props;
    const head = ReactDOM?.findDOMNode(this.title.current)?.getBoundingClientRect();
    const bar = ReactDOM?.findDOMNode(this.lightbar.current)?.getBoundingClientRect();
    const light = ReactDOM?.findDOMNode(this.lights.current)?.getBoundingClientRect();
    const warning = ReactDOM?.findDOMNode(this.warningMessage.current)?.getBoundingClientRect();
    if (light?.top) {
      setLightsHeight(560 - parseInt(light.top - head.top + 55, 10));
    }
    if (bar?.top) {
      setLightBarHeight(parseInt(bar.top - head.top + 55, 10));
    }
    if (warning?.top) {
      setWarningHeight(parseInt(warning.top - head.top + 55, 10));
    }
  }

  callingStatement = () => (
    <div>
      Calling
      <span style={{ marginLeft: "0.01rem" }}> </span>
      <span>for</span>
      <span style={{ marginLeft: "0.01rem" }}> </span>
      <span>right</span>
      <span style={{ marginLeft: "0.04rem" }}> </span>
      <span>of</span>
      <span style={{ marginLeft: "0.04rem" }}> </span>
      <span>way</span>
    </div>
  );

  primaryEmergencyCalling = () => (
    <div>
      Primary
      <span style={{ marginLeft: "0.01rem" }}> </span>
      <span>Emergency</span>
    </div>
  );

  blockingStatement = () => (
    <div>
      Blocking
      <span style={{ marginLeft: "0.01rem" }}> </span>
      <span>for</span>
      <span style={{ marginLeft: "0.01rem" }}> </span>
      <span>right</span>
      <span style={{ marginLeft: "0.04rem" }}> </span>
      <span>of</span>
      <span style={{ marginLeft: "0.04rem" }}> </span>
      <span>way</span>
    </div>
  );

  // display available filters and lens color for lightbar
  filterAndLensColorDisplay = (data) => (
    <div>
      <span className="break-word">{data?.lensColor ? `Lens Color: ${data?.lensColor} ` : null}</span>
      <span className="break-word">{data?.filterModel ? `\nFilter Model: ${data?.filterModel} ` : null}</span>
      <span className="break-word">{data?.filterColor ? `\nFilter Color: ${data?.filterColor} ` : null}</span>
    </div>
  );

  lightBarDiv = (bars) => {
    const isSafari = browser?.name === "safari";
    return bars.map((light) => (
      <div style={{ marginBottom: "8px" }}>
        <div className="font-7">
          <span>
            {`${light.series ? `${light.series} ` : ""}${light.size ? `${light.size} ` : ""}${light.modelNumber}`}
            {` - QTY: ${light.qty}`} &nbsp;&nbsp;
            <span className="font-6">
              <strong>ROTATION: </strong>
            </span>{" "}
            {light.rotation}
          </span>
        </div>
        <div className="d-flex" style={{ width: "370px" }}>
          <div className="sub-headers-percent" style={!isSafari ? { overflowWrap: "break-word" } : {}}>
            <div className="mr-3">
              <span className="font-wt font-6">FRONT DIRECTIONAL: </span>
              <br />
              {light?.fronDirectional.map((data) => (
                <div className="font-5">
                  {data?.name}
                  {` - QTY: ${data?.qty}`}
                  {`\n`}
                  {this.filterAndLensColorDisplay(data)}
                </div>
              ))}
            </div>
          </div>
          <div className="sub-headers-percent" style={!isSafari ? { overflowWrap: "break-word" } : {}}>
            <div className="mr-3">
              <span className="font-wt font-6">REAR DIRECTIONAL: </span>
              <br />
              {light?.rearDirectional.map((data) => (
                <div className="font-5">
                  {data?.name}
                  {` - QTY: ${data?.qty}`}
                  {`\n`}
                  {this.filterAndLensColorDisplay(data)}
                </div>
              ))}
            </div>
          </div>
          <div className="sub-headers-percent" style={!isSafari ? { overflowWrap: "break-word" } : {}}>
            <div className="mr-3 font-5">
              <span className="font-wt font-6">CORNERS: </span>
              <br />
              {light?.corners.map((data) => (
                <div className="font-5">
                  {data?.name}
                  {` - QTY: ${data?.qty}`}
                  {`\n`}
                  {this.filterAndLensColorDisplay(data)}
                </div>
              ))}
            </div>
          </div>
          <div className="sub-headers-percent" style={!isSafari ? { overflowWrap: "break-word" } : {}}>
            <div className="mr-3">
              <span className="font-wt font-6">ALLEYS: </span>
              <br />
              {light?.alleys.map((data) => (
                <div className="font-5">
                  {data?.name}
                  {` - QTY: ${data?.qty}`}
                  {`\n`}
                  {this.filterAndLensColorDisplay(data)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    ));
  };

  render() {
    const {
      configDetail,
      finalResultsData,
      lightsData,
      lightPageBreakDetails,
      lightbarPageBreakDetails,
      excludedLightsInZone,
      warningDataPdf,
      totalPageInWarningData,
      printWarning,
      opSystem,
    } = this.props;
    const isSafari = browser?.name === "safari";
    const isFirefox = browser?.name === "firefox";
    // prepare data for unused zone
    // eslint-disable-next-line array-callback-return
    Object.keys(lightsData).map((zone) => {
      if (lightsData[zone].length === 0 && !excludedLightsInZone?.zone) {
        const key = zone;
        lightsData[key][0] = {
          lightSource: "",
          qty: "",
          obstructed: [],
          currentMax: "",
          currentAverage: "",
        };
      }
    });
    const ZonePeak = (ZoneData) => {
      let zonePeak = 0;
      ZoneData.forEach((element) => {
        const currentMax = parseFloat(element.currentMax * element.qty);
        zonePeak += currentMax;
      });
      return `${zonePeak.toFixed(2)}A`;
    };
    const ZoneAvg = (ZoneData) => {
      let zoneAvg = 0;
      ZoneData.forEach((element) => {
        const currentAvg = parseFloat(element.currentAverage * element.qty);
        zoneAvg += currentAvg;
      });
      return `${zoneAvg.toFixed(2)}A`;
    };
    const checkIndex = (zone, index) => {
      if (lightPageBreakDetails && Object.prototype.hasOwnProperty.call(lightPageBreakDetails, zone + index)) {
        return lightPageBreakDetails[zone + index];
      }
      return false;
    };
    const getFooterMargin = (position) => {
      let pagebreak = 0;
      if (
        lightPageBreakDetails &&
        Object.keys(lightPageBreakDetails)?.length &&
        lightbarPageBreakDetails?.completePages &&
        Object.keys(lightbarPageBreakDetails?.completePages).length
      ) {
        pagebreak =
          Object.keys(lightbarPageBreakDetails?.completePages).length + Object.keys(lightPageBreakDetails)?.length;
      } else if (
        lightbarPageBreakDetails?.completePages &&
        Object.keys(lightbarPageBreakDetails?.completePages).length
      ) {
        pagebreak = Object.keys(lightbarPageBreakDetails?.completePages).length;
      } else if (lightPageBreakDetails && Object.keys(lightPageBreakDetails)?.length) {
        pagebreak = Object.keys(lightPageBreakDetails)?.length;
      }
      if (printWarning && position === "warnings") {
        pagebreak += totalPageInWarningData; // warning data addition
      }
      return `${590 + 631 * pagebreak}px`;
    };
    const breakDetails = (data, length) => {
      if (!isSafari) {
        return data;
      }
      const array = data.match(new RegExp(`.{1,${length}}`, "g")) || [];
      return array.join("\r\n");
    };
    const CalculationSummary = () => (
      <>
        <div className="d-flex mb-3" style={{ marginLeft: "157px" }}>
          <div className="title" style={!isSafari ? { letterSpacing: "-0.3px" } : {}}>
            <p />
          </div>
        </div>
        <div className="d-flex">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <table className="zone-table-info info-table">
                <tr>
                  <td>
                    <table>
                      <tr>
                        <th style={{ marginBottom: "1px" }}>CALCULATION DATE: </th>
                        <td
                          className="word-space"
                          style={
                            isSafari
                              ? { paddingTop: "1px" }
                              : isFirefox && opSystem !== OP_SYSTEM.MAC
                              ? { paddingTop: "2px" }
                              : isFirefox && opSystem === OP_SYSTEM.MAC
                              ? { paddingTop: "0px" }
                              : { marginTop: "1px" }
                          }
                        >
                          {configDetail.date}
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <table
                      style={
                        isSafari
                          ? {
                              maxWidth: "100px",
                              lineHeight: "2.14",
                            }
                          : {
                              lineHeight: "2.14",
                            }
                      }
                    >
                      <tr>
                        <th style={{ marginBottom: "1px" }}>STANDARD: </th>
                        <td
                          className="word-space"
                          style={
                            isSafari
                              ? { paddingTop: "1px" }
                              : isFirefox && opSystem !== OP_SYSTEM.MAC
                              ? { overflowWrap: "break-word", maxWidth: "150px", paddingTop: "2px" }
                              : isFirefox && opSystem === OP_SYSTEM.MAC
                              ? { overflowWrap: "break-word", maxWidth: "150px", paddingTop: "0px" }
                              : { overflowWrap: "break-word", maxWidth: "150px", marginTop: "1px" }
                          }
                        >
                          {configDetail.standard}
                        </td>
                      </tr>
                    </table>
                  </td>

                  <td>
                    <table
                      style={
                        isSafari
                          ? {
                              maxWidth: "160px",
                              lineHeight: "2.14",
                            }
                          : {
                              lineHeight: "2.14",
                            }
                      }
                    >
                      <tr>
                        <th style={{ marginBottom: "1px" }}>CREATED BY: </th>
                        {configDetail.user.length < 25 || !isSafari ? (
                          <td
                            className="word-space"
                            style={
                              isSafari
                                ? { paddingTop: "1px" }
                                : isFirefox && opSystem !== OP_SYSTEM.MAC
                                ? { overflowWrap: "break-word", maxWidth: "150px", paddingTop: "2px" }
                                : isFirefox && opSystem === OP_SYSTEM.MAC
                                ? { overflowWrap: "break-word", maxWidth: "150px", paddingTop: "0px" }
                                : { overflowWrap: "break-word", maxWidth: "130px", marginTop: "1px" }
                            }
                          >
                            {configDetail.user}
                          </td>
                        ) : (
                          <td
                            className="word-space"
                            style={
                              isSafari
                                ? { paddingTop: "3px" }
                                : isFirefox
                                ? { overflowWrap: "break-word", maxWidth: "130px", paddingTop: "4px" }
                                : { overflowWrap: "break-word", maxWidth: "130px" }
                            }
                          >
                            <span>
                              {breakDetails(configDetail.user.split(" ")[0], 25)} <br />
                              {breakDetails(configDetail.user.split(" ")[1], 25)}
                            </span>
                          </td>
                        )}
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="r-block">
            <table className="intensity-table-new">
              <tr>
                <th className="mb-0 font-7 mr-2">SYSTEM TYPE:</th>
                <th className="mb-0 font-7 mr-2">VEHICLE:</th>
                <th className="mb-0 font-7 mr-2">VIN:</th>
                <th className="mb-0 font-7 mr-2">SIZE:</th>
                <th className="mb-0 font-7 mr-2">DEPT:</th>
                <th className="mb-0 font-7 mr-2">JOB:</th>
                <th className="mb-0 font-7 mr-2">CONTRACT:</th>
                <th className="mb-0 font-7 mr-2">
                  TOTAL<span className="pl-05">CURRENT</span> DRAW:
                </th>
              </tr>
              <tr>
                <td className="font-6" style={!isSafari ? { overflowWrap: "break-word" } : {}}>
                  {configDetail.sytemType}
                </td>
                <td
                  className="font-6"
                  style={!isSafari ? { overflowWrap: "break-word", maxWidth: "35px" } : { maxWidth: "35px" }}
                >
                  {breakDetails(configDetail.vehicle, 7)}
                </td>
                <td
                  className="font-6"
                  style={!isSafari ? { overflowWrap: "break-word", maxWidth: "35px" } : { maxWidth: "35px" }}
                >
                  {breakDetails(configDetail.vin, 7)}
                </td>
                <td className="font-6">
                  {configDetail?.standard === STANDARD?.ULC515_2024 && configDetail?.size === SIZES?.GREATER_THAN ? (
                    ">7.62m"
                  ) : configDetail?.standard === STANDARD?.ULC515_2024 && configDetail?.size === SIZES?.LESS_THAN ? (
                    "<7.62m"
                  ) : configDetail?.size === SIZES?.GREATER_THAN ? (
                    ">25'"
                  ) : configDetail?.size === SIZES?.LESS_THAN ? (
                    "< 25'"
                  ) : (
                    <>
                      <span>Any</span>
                      <br />
                      <span>Size</span>
                    </>
                  )}
                </td>
                <td
                  className="font-6"
                  style={!isSafari ? { overflowWrap: "break-word", maxWidth: "35px" } : { maxWidth: "35px" }}
                >
                  {breakDetails(configDetail.department, 7)}
                </td>
                <td
                  className="font-6"
                  style={!isSafari ? { overflowWrap: "break-word", maxWidth: "35px" } : { maxWidth: "35px" }}
                >
                  {breakDetails(configDetail.job, 7)}
                </td>
                <td
                  className="font-6"
                  style={!isSafari ? { overflowWrap: "break-word", maxWidth: "35px" } : { maxWidth: "35px" }}
                >
                  {breakDetails(configDetail.contract, 7)}
                </td>
                <td className="font-6">
                  <span>
                    PEAK: {configDetail.currentMax} {configDetail.currentMax === "N/A" ? "" : "A"}
                  </span>
                  <br />
                  AVERAGE: {configDetail.currentAverage} {configDetail.currentAverage === "N/A" ? "" : "A"}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </>
    );
    return (
      <div className="pdf mb-0" ref={this.title} style={isFirefox ? { lineHeight: "1.7" } : { lineHeight: "1.8" }}>
        <div className="page mb-0" style={{ marginTop: "55px" }}>
          <div className="card-body p-0">
            <CalculationSummary />
            {finalResultsData.size ? (
              <div>
                <div className="title mb-0 mt-2 font-9" style={!isSafari ? { letterSpacing: "-0.3px" } : {}}>
                  RESULTS
                </div>
                <div className="d-flex">
                  <div className="r-block">
                    {finalResultsData.has("zoneacallingupper") ||
                    finalResultsData.has("zoneacalling") ||
                    finalResultsData.has("zoneablockingupper") ||
                    finalResultsData.has("zoneablocking") ? (
                      <table className={`${isSafari ? "intensity-table-safari" : "intensity-table"} mr-3`}>
                        <th className="mb-0 font-6">
                          ZONE A{" "}
                          {finalResultsData.has("zoneacallingupper") || finalResultsData.has("zoneablockingupper")
                            ? "UPPER"
                            : ""}
                        </th>
                        {finalResultsData?.has("zoneacallingupper") || finalResultsData.has("zoneacalling") ? (
                          <tr>
                            <td>
                              {configDetail.standard === ONTARIO.NAME
                                ? this.primaryEmergencyCalling()
                                : this.callingStatement()}
                            </td>
                            <td className="text-right">
                              <span
                                className={`text-right ${
                                  finalResultsData.has("zoneacallingupper")
                                    ? finalResultsData.get("zoneacallingupper").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                    : finalResultsData.get("zoneacalling").result === "Pass"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {finalResultsData.has("zoneacallingupper")
                                  ? finalResultsData.get("zoneacallingupper").result
                                  : finalResultsData.get("zoneacalling").result}
                              </span>
                            </td>
                          </tr>
                        ) : null}
                        {finalResultsData.has("zoneablockingupper") || finalResultsData.has("zoneablocking") ? (
                          <tr>
                            <td>{this.blockingStatement()}</td>
                            <td className="text-right">
                              <span
                                className={`text-right ${
                                  finalResultsData.has("zoneablockingupper")
                                    ? finalResultsData.get("zoneablockingupper").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                    : finalResultsData.get("zoneablocking").result === "Pass"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {finalResultsData.has("zoneablockingupper")
                                  ? finalResultsData.get("zoneablockingupper").result
                                  : finalResultsData.get("zoneablocking").result}
                              </span>
                            </td>
                          </tr>
                        ) : null}
                      </table>
                    ) : null}
                  </div>
                  <div className="r-block">
                    {finalResultsData.has("zonebcallingupper") ||
                    finalResultsData.has("zonebcalling") ||
                    finalResultsData.has("zonebblockingupper") ||
                    finalResultsData.has("zonebblocking") ? (
                      <table className={`${isSafari ? "intensity-table-safari" : "intensity-table"} mr-3`}>
                        <th className="mb-0 font-6">
                          ZONE B{" "}
                          {finalResultsData.has("zonebcallingupper") || finalResultsData.has("zonebblockingupper")
                            ? "UPPER"
                            : ""}
                        </th>
                        {finalResultsData.has("zonebcallingupper") || finalResultsData.has("zonebcalling") ? (
                          <tr>
                            <td>
                              {configDetail.standard === ONTARIO.NAME
                                ? this.primaryEmergencyCalling()
                                : this.callingStatement()}
                            </td>
                            <td className="text-right">
                              <span
                                className={`text-right ${
                                  finalResultsData.has("zonebcallingupper")
                                    ? finalResultsData.get("zonebcallingupper").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                    : finalResultsData.get("zonebcalling").result === "Pass"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {finalResultsData.has("zonebcallingupper")
                                  ? finalResultsData.get("zonebcallingupper").result
                                  : finalResultsData.get("zonebcalling").result}
                              </span>
                            </td>
                          </tr>
                        ) : null}
                        {finalResultsData.has("zonebblockingupper") || finalResultsData.has("zonebblocking") ? (
                          <tr>
                            <td>{this.blockingStatement()}</td>
                            <td className="text-right">
                              <span
                                className={`text-right ${
                                  finalResultsData.has("zonebblockingupper")
                                    ? finalResultsData.get("zonebblockingupper").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                    : finalResultsData.get("zonebblocking").result === "Pass"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {finalResultsData.has("zonebblockingupper")
                                  ? finalResultsData.get("zonebblockingupper").result
                                  : finalResultsData.get("zonebblocking").result}
                              </span>
                            </td>
                          </tr>
                        ) : null}
                      </table>
                    ) : null}
                  </div>
                  <div className="r-block">
                    {finalResultsData.has("zoneccallingupper") ||
                    finalResultsData.has("zoneccalling") ||
                    finalResultsData.has("zonecblockingupper") ||
                    finalResultsData.has("zonecblocking") ? (
                      <table className={`${isSafari ? "intensity-table-safari" : "intensity-table"} mr-3`}>
                        <th className="mb-0 font-6">
                          ZONE C{" "}
                          {finalResultsData.has("zoneccallingupper") || finalResultsData.has("zonecblockingupper")
                            ? "UPPER"
                            : ""}
                        </th>
                        {finalResultsData.has("zoneccallingupper") || finalResultsData.has("zoneccalling") ? (
                          <tr>
                            <td>
                              {configDetail.standard === ONTARIO.NAME
                                ? this.primaryEmergencyCalling()
                                : this.callingStatement()}
                            </td>
                            <td className="text-right">
                              <span
                                className={`text-right ${
                                  finalResultsData.has("zoneccallingupper")
                                    ? finalResultsData.get("zoneccallingupper").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                    : finalResultsData.get("zoneccalling").result === "Pass"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {finalResultsData.has("zoneccallingupper")
                                  ? finalResultsData.get("zoneccallingupper").result
                                  : finalResultsData.get("zoneccalling").result}
                              </span>
                            </td>
                          </tr>
                        ) : null}
                        {finalResultsData.has("zonecblockingupper") || finalResultsData.has("zonecblocking") ? (
                          <tr>
                            <td>{this.blockingStatement()}</td>
                            <td className="text-right">
                              <span
                                className={`text-right ${
                                  finalResultsData.has("zonecblockingupper")
                                    ? finalResultsData.get("zonecblockingupper").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                    : finalResultsData.get("zonecblocking").result === "Pass"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {finalResultsData.has("zonecblockingupper")
                                  ? finalResultsData.get("zonecblockingupper").result
                                  : finalResultsData.get("zonecblocking").result}
                              </span>
                            </td>
                          </tr>
                        ) : null}
                      </table>
                    ) : null}
                  </div>
                  <div className="r-block">
                    {finalResultsData.has("zonedcallingupper") ||
                    finalResultsData.has("zonedcalling") ||
                    finalResultsData.has("zonedblockingupper") ||
                    finalResultsData.has("zonedblocking") ? (
                      <table className={`${isSafari ? "intensity-table-safari" : "intensity-table"} mr-3`}>
                        <th className="mb-0 font-6">
                          ZONE D{" "}
                          {finalResultsData.has("zonedcallingupper") || finalResultsData.has("zonedblockingupper")
                            ? "UPPER"
                            : ""}
                        </th>
                        {finalResultsData.has("zonedcallingupper") || finalResultsData.has("zonedcalling") ? (
                          <tr>
                            <td>
                              {configDetail.standard === ONTARIO.NAME
                                ? this.primaryEmergencyCalling()
                                : this.callingStatement()}
                            </td>
                            <td className="text-right">
                              <span
                                className={`text-right ${
                                  finalResultsData.has("zonedcallingupper")
                                    ? finalResultsData.get("zonedcallingupper").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                    : finalResultsData.get("zonedcalling").result === "Pass"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {finalResultsData.has("zonedcallingupper")
                                  ? finalResultsData.get("zonedcallingupper").result
                                  : finalResultsData.get("zonedcalling").result}
                              </span>
                            </td>
                          </tr>
                        ) : null}
                        {finalResultsData.has("zonedblockingupper") || finalResultsData.has("zonedblocking") ? (
                          <tr>
                            <td>{this.blockingStatement()}</td>
                            <td className="text-right">
                              <span
                                className={`text-right ${
                                  finalResultsData.has("zonedblockingupper")
                                    ? finalResultsData.get("zonedblockingupper").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                    : finalResultsData.get("zonedblocking").result === "Pass"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {finalResultsData.has("zonedblockingupper")
                                  ? finalResultsData.get("zonedblockingupper").result
                                  : finalResultsData.get("zonedblocking").result}
                              </span>
                            </td>
                          </tr>
                        ) : null}
                      </table>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="r-block">
                    {finalResultsData.has("zoneacallinglower") || finalResultsData.has("zoneablockinglower") ? (
                      <table className={`${isSafari ? "intensity-table-safari" : "intensity-table"} mr-3`}>
                        <th className="mb-0 font-6">ZONE A LOWER</th>
                        {finalResultsData.has("zoneacallinglower") ? (
                          <tr>
                            <td>
                              {configDetail.standard === ONTARIO.NAME
                                ? this.primaryEmergencyCalling()
                                : this.callingStatement()}
                            </td>
                            <td className="text-right">
                              <span
                                className={`text-right ${
                                  finalResultsData.get("zoneacallinglower").result === "Pass"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {finalResultsData.get("zoneacallinglower").result}
                              </span>
                            </td>
                          </tr>
                        ) : null}
                        {finalResultsData.has("zoneablockinglower") ? (
                          <tr>
                            <td>{this.blockingStatement()}</td>
                            <td className="text-right">
                              <span
                                className={`text-right ${
                                  finalResultsData.get("zoneablockinglower").result === "Pass"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {finalResultsData.get("zoneablockinglower").result}
                              </span>
                            </td>
                          </tr>
                        ) : null}
                      </table>
                    ) : null}
                  </div>
                  <div className="r-block">
                    {finalResultsData.has("zonebcallinglower") || finalResultsData.has("zonebblockinglower") ? (
                      <table className={`${isSafari ? "intensity-table-safari" : "intensity-table"} mr-3`}>
                        <th className="mb-0 font-6">ZONE B LOWER</th>
                        {finalResultsData.has("zonebcallinglower") ? (
                          <tr>
                            <td>
                              {configDetail.standard === ONTARIO.NAME
                                ? this.primaryEmergencyCalling()
                                : this.callingStatement()}
                            </td>
                            <td className="text-right">
                              <span
                                className={`text-right ${
                                  finalResultsData.get("zonebcallinglower").result === "Pass"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {finalResultsData.get("zonebcallinglower").result}
                              </span>
                            </td>
                          </tr>
                        ) : null}
                        {finalResultsData.has("zonebblockinglower") ? (
                          <tr>
                            <td>{this.blockingStatement()}</td>
                            <td className="text-right">
                              <span
                                className={`text-right ${
                                  finalResultsData.get("zonebblockinglower").result === "Pass"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {finalResultsData.get("zonebblockinglower").result}
                              </span>
                            </td>
                          </tr>
                        ) : null}
                      </table>
                    ) : null}
                  </div>
                  <div className="r-block">
                    {finalResultsData.has("zoneccallinglower") || finalResultsData.has("zonecblockinglower") ? (
                      <table className={`${isSafari ? "intensity-table-safari" : "intensity-table"} mr-3`}>
                        <th className="mb-0 font-6">ZONE C LOWER</th>
                        {finalResultsData.has("zoneccallinglower") ? (
                          <tr>
                            <td>
                              {configDetail.standard === ONTARIO.NAME
                                ? this.primaryEmergencyCalling()
                                : this.callingStatement()}
                            </td>
                            <td className="text-right">
                              <span
                                className={`text-right ${
                                  finalResultsData.get("zoneccallinglower").result === "Pass"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {finalResultsData.get("zoneccallinglower").result}
                              </span>
                            </td>
                          </tr>
                        ) : null}
                        {finalResultsData.has("zonecblockinglower") ? (
                          <tr>
                            <td>{this.blockingStatement()}</td>
                            <td className="text-right">
                              <span
                                className={`text-right ${
                                  finalResultsData.get("zonecblockinglower").result === "Pass"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {finalResultsData.get("zonecblockinglower").result}
                              </span>
                            </td>
                          </tr>
                        ) : null}
                      </table>
                    ) : null}
                  </div>
                  <div className="r-block">
                    {finalResultsData.has("zonedcallinglower") || finalResultsData.has("zonedblockinglower") ? (
                      <table className={`${isSafari ? "intensity-table-safari" : "intensity-table"} mr-3`}>
                        <th className="mb-0 font-6">ZONE D LOWER</th>
                        {finalResultsData.has("zonedcallinglower") ? (
                          <tr>
                            <td>
                              {configDetail.standard === ONTARIO.NAME
                                ? this.primaryEmergencyCalling()
                                : this.callingStatement()}
                            </td>
                            <td className="text-right">
                              <span
                                className={`text-right ${
                                  finalResultsData.get("zonedcallinglower").result === "Pass"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {finalResultsData.get("zonedcallinglower").result}
                              </span>
                            </td>
                          </tr>
                        ) : null}
                        {finalResultsData.has("zonedblockinglower") ? (
                          <tr>
                            <td>{this.blockingStatement()}</td>
                            <td className="text-right">
                              <span
                                className={`text-right ${
                                  finalResultsData.get("zonedblockinglower").result === "Pass"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {finalResultsData.get("zonedblockinglower").result}
                              </span>
                            </td>
                          </tr>
                        ) : null}
                      </table>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
            {Object.keys(lightsData).length ? (
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <table className="zone-table">
                    <tr ref={this.lights}>
                      <th>{lightsData.length ? " " : " "}</th>
                      <th>
                        <span>LIGHT</span>
                        <br />
                        <span>SOURCE</span>
                      </th>
                      <th>QTY</th>
                      <th className="pad-left">
                        <span>ZONE</span>
                        <br /> <span>PEAK</span>
                      </th>
                      <th>
                        <span>ZONE</span>
                        <br />
                        <span>AVG</span>
                      </th>
                      <th>OBSTRUCTED</th>
                      <th>ROTATION</th>
                      <th>
                        CALLING <br /> INTENSITY
                      </th>
                      {configDetail.standard !== "OPLA & ERV 5.0" ? (
                        <th>
                          BLOCKING <br /> INTENSITY
                        </th>
                      ) : null}
                    </tr>

                    {Object.keys(lightsData).map((zone) =>
                      lightsData[zone].map((light, ind) => (
                        <>
                          {checkIndex(zone, ind) ? (
                            <tr>
                              <th style={checkIndex(zone, ind) ? { paddingTop: checkIndex(zone, ind) } : {}}>
                                {lightsData.length ? " " : " "}
                              </th>
                              <th style={checkIndex(zone, ind) ? { paddingTop: checkIndex(zone, ind) } : {}}>
                                <span>LIGHT</span>
                                <br />
                                <span>SOURCE</span>
                              </th>
                              <th style={checkIndex(zone, ind) ? { paddingTop: checkIndex(zone, ind) } : {}}>QTY</th>
                              <th
                                className="pad-left"
                                style={checkIndex(zone, ind) ? { paddingTop: checkIndex(zone, ind) } : {}}
                              >
                                <span>ZONE</span>
                                <br />
                                <span>PEAK</span>
                              </th>
                              <th style={checkIndex(zone, ind) ? { paddingTop: checkIndex(zone, ind) } : {}}>
                                <span>ZONE</span>
                                <br />
                                <span>AVG</span>
                              </th>
                              <th style={checkIndex(zone, ind) ? { paddingTop: checkIndex(zone, ind) } : {}}>
                                OBSTRUCTED
                              </th>
                              <th style={checkIndex(zone, ind) ? { paddingTop: checkIndex(zone, ind) } : {}}>
                                ROTATION
                              </th>
                              <th style={checkIndex(zone, ind) ? { paddingTop: checkIndex(zone, ind) } : {}}>
                                CALLING <br /> INTENSITY
                              </th>
                              {configDetail.standard !== "OPLA & ERV 5.0" ? (
                                <th style={checkIndex(zone, ind) ? { paddingTop: checkIndex(zone, ind) } : {}}>
                                  BLOCKING <br /> INTENSITY
                                </th>
                              ) : null}
                            </tr>
                          ) : null}
                          <tr className={`${light.excluded ? "strikeout" : ""}`}>
                            <th>
                              {ind === 0 ? (zone.includes("single") ? zone.split("single")[0].trim() : zone) : ""}
                            </th>
                            <td className="sub-headers-draw" style={!isSafari ? { overflowWrap: "break-word" } : {}}>
                              {light?.lightSource && light.color
                                ? `${light?.lightSource}-${light.color}`
                                : light?.lightSource
                                ? light?.lightSource
                                : ""}
                            </td>
                            <td>{light?.displayQty ? light.qty : ""}</td>
                            <td className="pad-left">{ind === 0 && light.qty ? ZonePeak(lightsData[zone]) : ""}</td>
                            <td>{ind === 0 && light.qty ? ZoneAvg(lightsData[zone]) : ""}</td>
                            <td>{light.obstructed.toString()}</td>
                            <td>{light.rotation}</td>
                            <td style={!isSafari ? { overflowWrap: "break-word" } : {}}>
                              {light.call_intensity ? light.call_intensity : ""}
                              {configDetail.sytemType !== "Standalone Lights" &&
                              light.call_intensity &&
                              light.call_intensity !== "Steady" &&
                              light.call_intensity !== "0%"
                                ? "%"
                                : ""}
                            </td>
                            {configDetail.standard !== "OPLA & ERV 5.0" ? (
                              <td style={!isSafari ? { overflowWrap: "break-word" } : {}}>
                                {light.block_intensity ? light.block_intensity : ""}
                                {configDetail.sytemType !== "Standalone Lights" &&
                                light.block_intensity &&
                                light.block_intensity !== "Steady" &&
                                light.block_intensity !== "0%"
                                  ? "%"
                                  : ""}
                              </td>
                            ) : null}
                          </tr>
                          {"importedBxrFileName" in light && light.importedBxrFileName !== null ? (
                            <tr>
                              <td>&nbsp;</td>
                              <td colSpan="8">
                                <small>File Name : {light?.importedBxrFileName}</small>
                              </td>
                            </tr>
                          ) : null}
                        </>
                      ))
                    )}
                  </table>
                </div>
              </div>
            ) : null}
            <div ref={this.lightbar} />
            {lightbarPageBreakDetails &&
            lightbarPageBreakDetails?.partialPage &&
            lightbarPageBreakDetails?.partialPage?.length ? (
              <div
                style={{
                  height: `${lightbarPageBreakDetails?.partialSpace ? lightbarPageBreakDetails?.partialSpace : 0}px`,
                  paddingBottom: "60px",
                }}
              >
                <div className="pt-3 mb-2">
                  <div className="title mb-0" style={!isSafari ? { letterSpacing: "-0.3px" } : {}}>
                    LIGHTBARS
                  </div>
                  <div className="footerText font-4" style={!isSafari ? { overflowWrap: "break-word" } : {}}>
                    <sup>*</sup>
                    See addendum for lightbar layout
                  </div>
                </div>
                {this.lightBarDiv(lightbarPageBreakDetails?.partialPage ? lightbarPageBreakDetails?.partialPage : [])}
              </div>
            ) : null}
            {lightbarPageBreakDetails &&
            lightbarPageBreakDetails?.partialSpace &&
            !lightbarPageBreakDetails?.partialPage?.length ? (
              <div
                style={{
                  height: `${lightbarPageBreakDetails?.partialSpace ? lightbarPageBreakDetails?.partialSpace : 0}px`,
                }}
              />
            ) : null}
            {lightbarPageBreakDetails?.completePages ? (
              <div>
                {Object.keys(lightbarPageBreakDetails?.completePages).map((barArray) => (
                  <div
                    style={{
                      height: "630px",
                      paddingTop: "60px",
                      paddingBottom: "60px",
                      overflowWrap: isSafari ? "" : "break-word",
                      lineHeight: isFirefox ? "1.58" : "1.65",
                    }}
                    className="word-space font-6"
                  >
                    {lightbarPageBreakDetails &&
                    lightbarPageBreakDetails?.partialSpace &&
                    !lightbarPageBreakDetails?.partialPage?.length &&
                    barArray === "0" ? (
                      <div className="mb-2">
                        <div className="title mb-0" style={!isSafari ? { letterSpacing: "-0.3px" } : {}}>
                          LIGHTBARS
                        </div>
                        <div className="footerText font-4" style={!isSafari ? { overflowWrap: "break-word" } : {}}>
                          <sup>*</sup>
                          See addendum for lightbar layout
                        </div>
                      </div>
                    ) : null}
                    {this.lightBarDiv(lightbarPageBreakDetails?.completePages[barArray])}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div className="text-center font-4 cust-footer" style={{ top: getFooterMargin("lights") }}>
            <div className="footerText ml-4" style={!isSafari ? { overflowWrap: "break-word" } : {}}>
              {configDetail.footerText}
            </div>
          </div>
          <div ref={this.warningMessage} />
          {printWarning ? (
            <>
              <div style={{ marginTop: lightbarPageBreakDetails?.partialSpace % 1 === 0 ? "55px" : "66px" }}>
                <CalculationSummary />
              </div>
              <div
                style={{
                  maxHeight: "200px",
                  paddingTop: "15px",
                  overflowWrap: isSafari ? "" : "break-word",
                  lineHeight: isFirefox ? "1.58" : "1.65",
                }}
                className="word-space font-6"
              >
                <div className="warningContent">
                  <div className="mb-1">
                    <div className="title mb-0 font-9" style={{ letterSpacing: "-0.4px" }}>
                      Warnings
                    </div>
                  </div>
                  <div style={{ fontSize: isFirefox ? "7.45px" : "7.5px" }} width="80%">
                    {warningDataPdf.map((light) => (
                      <div style={{ paddingTop: `${light.rowMargin}px`, lineHeight: isFirefox ? "1.40" : "1.48" }}>
                        {light.data}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="text-center font-4 cust-footer" style={{ top: getFooterMargin("warnings") }}>
                <div className="footerText ml-4" style={!isSafari ? { overflowWrap: "break-word" } : {}}>
                  {configDetail.footerText}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

export default PdfMoz;
