/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// component for Low Power
import React from "react";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import helper from "../../../helper/helper";
import unchecked from "../../../assets/images/white.png";
import checked from "../../../assets/images/squre-round.png";
import { LOWPOWER_TOOLTIP, ZONE } from "../../../services/Constant";

const LowPowerComponent = ({
  systemType,
  setLowPower,
  enableLowPower,
  setIntensity,
  onSetLowPower,
  onChangeZoneIntensity,
  lowPowerOptions,
  zone,
  isMirrorToZoneD,
  isMirrorToZoneB,
}) => {
  const handleClick = () => {};
  return (
    <>
      <div
        className={
          !enableLowPower || (isMirrorToZoneD && zone === ZONE.ZONE_D) || (isMirrorToZoneB && zone === ZONE.ZONE_B)
            ? "d-flex align-items-center cursor-na"
            : "d-flex align-items-center"
        }
      >
        {setLowPower && enableLowPower ? (
          <img
            src={checked}
            className={
              !enableLowPower || (isMirrorToZoneD && zone === ZONE.ZONE_D) || (isMirrorToZoneB && zone === ZONE.ZONE_B)
                ? "img-fluid mr-2 check-height cursor-na disable-image"
                : "img-fluid cursor-pt mr-2 check-height"
            }
            alt=""
            onClick={() => {
              onSetLowPower(false, zone);
            }}
            onKeyDown={handleClick}
            data-tip={LOWPOWER_TOOLTIP}
            data-for="lowpower"
          />
        ) : (
          <img
            src={unchecked}
            className={
              !enableLowPower || (isMirrorToZoneD && zone === ZONE.ZONE_D) || (isMirrorToZoneB && zone === ZONE.ZONE_B)
                ? "img-fluid mr-2 check-height cursor-na disable-image"
                : "img-fluid cursor-pt mr-2 check-height"
            }
            alt=""
            onClick={() => {
              onSetLowPower(true, zone);
            }}
            onKeyDown={handleClick}
            data-tip={LOWPOWER_TOOLTIP}
            data-for="lowpower"
          />
        )}
        <label className="pt-10 mr-3" htmlFor="lowPower">
          Set Low Power
        </label>
        <ReactTooltip place="right" multiline id="lowpower" type="light" effect="solid" className="text-left" />
        {systemType?.value !== "STANDALONE_LIGHTS" ? (
          <div
            className={`${
              !setLowPower || (isMirrorToZoneD && zone === ZONE.ZONE_D) || (isMirrorToZoneB && zone === ZONE.ZONE_B)
                ? "cursor-na"
                : "cursor-pt"
            }`}
          >
            <Select
              value={setIntensity}
              onChange={(e) => {
                onChangeZoneIntensity(e, zone);
              }}
              options={lowPowerOptions}
              isDisabled={
                (isMirrorToZoneD && zone === ZONE.ZONE_D) || (isMirrorToZoneB && zone === ZONE.ZONE_B) || !setLowPower
              }
              className="select-sdd mr-25 mg-top-15"
              autoFocus={false}
              styles={helper.getDropDownStyleZoneRtnAbs(
                (isMirrorToZoneD && zone === ZONE.ZONE_D) || (isMirrorToZoneB && zone === ZONE.ZONE_B) || !setLowPower
              )}
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default LowPowerComponent;
