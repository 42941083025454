/* eslint-disable prettier/prettier */
import React from "react";
import { Modal } from "react-bootstrap";
import Helpers from "../helper/helper";
import ConfigService from "./ConfigService";
import config from "../config/config";
import DeleteConfiguration from "../components/Pages/ZoneManagement/DeleteConfiguration";

const { SUCCESS_MESSAGE, ERROR_MESSAGE } = config;
// Set Arrow direction as per sorting order
const setArrow = (sort, column) => {
  let className = "sort-direction";

  if (sort.column === column) {
    className += sort.direction === "asc" ? " asc" : " desc";
  } else {
    className += " default";
  }
  return className;
};

// Compares two values
const compareValues = (a, b, column, direction) => {
  const nameA = a[column]?.toString().toUpperCase(); // ignore upper and lowercase
  const nameB = b[column]?.toString().toUpperCase(); // ignore upper and lowercase

  if (nameA < nameB || (nameA === nameB && direction === "asc")) {
    return -1;
  }
  if (nameA > nameB || (nameA === nameB && direction === "desc")) {
    return 1;
  }
  if (direction === "asc") {
    return -1;
  }
  return 1;
};

// To delete config
const deleteUserConfig = ({ configId, setLoading, closeDelConfigModal, setConfigData, navigateToHome, getConfigurations, pageSize,
   searchVal }) => {
  const configDetails = ConfigService?.getConfigDetailsFromStorage();
  setLoading(true);
  ConfigService?.deleteConfigurationMethod(configId)?.then((response) => {
    if (response?.status === 200 || response?.status === 204) {
      setLoading(false);
      Helpers.displayNotification(SUCCESS_MESSAGE.DELETE_CONFIG, "success");
      closeDelConfigModal();
      getConfigurations("", searchVal, pageSize);
      if(setConfigData){
      setConfigData((prevList) => prevList?.filter((configUser) => configUser?.id !== configId));
      }
      if (configDetails?.id === configId) {
        navigateToHome();
      }
    } else {
      setLoading(false);
      closeDelConfigModal();
      Helpers.displayNotification(ERROR_MESSAGE.DELETE_CONFIG_FAILED, "error");
    }
  })
  .catch((error) => {
        setLoading(false);
        console.log(error);
      });
};

// To open delete configuration modal
const getDeleteConfigModal = ({
  userConfigId,
  isDeleteModal,
  loading,
  setLoading,
  setIsDeleteModal,
  closeDelConfigModal,
  setConfigData,
  navigateToHome,
  modalClass,
  getConfigurations,
  pageSize,
  searchVal,
}) => (
  <Modal className={modalClass} backdrop="static" tabIndex="-1" show={isDeleteModal} onHide={setIsDeleteModal}>
    <DeleteConfiguration
      closeDelConfigModal={closeDelConfigModal}
      delConfigData={() =>
        deleteUserConfig({
          configId: userConfigId.id,
          setLoading,
          closeDelConfigModal,
          setConfigData,
          navigateToHome,
          getConfigurations,
          pageSize,
          searchVal,
        })}
      loading={loading}
      userConfigId={userConfigId}
    />
  </Modal>
);

const CommonUtiles = {
  setArrow,
  compareValues,
  getDeleteConfigModal,
};

export default CommonUtiles;
