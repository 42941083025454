// API url for production environment
const prod = {
  API_URL: "https://api.aog.whelen.com",
};

// API url for staging environment
const staging = {
  API_URL: "https://staging-api.aog.whelen.com",
};

// API url for dev environment
const dev = {
  API_URL: "https://dev-api.aog.whelen.com",
};

// API url for test environment
const test = {
  API_URL: "https://dev-api.aog.whelen.com",
};

// Set value of config variable as per the value of env variable REACT_APP_STAGE
const config =
  process.env.REACT_APP_STAGE === "prod"
    ? prod
    : process.env.REACT_APP_STAGE === "staging"
    ? staging
    : process.env.REACT_APP_STAGE === "test"
    ? test
    : dev;

export default {
  ERROR_MESSAGE: {
    UNAUTHORIZED_REQUEST: "Wrong Email Address or Password. Try again or click on Trouble logging in.",
    ACCESS_DENIED: "Wrong Email Address or Password. Try again or click on Trouble logging in.",
    UNAUTHORIZED_USER: "Your account has been disabled. Please contact Whelen for assistance",
    USER_BAD_REQUEST: "Required parameters are missing.",
    SOMETHING_WENT_WRONG: "Something went wrong. Please try later.",
    USER_NOT_FOUND: "User not found.",
    ACCOUNT_IS_NOT_ACTIVE: "Your account is not active, Please contact Admin team",
    INCORRECT_CURRENT_PASSWORD: "Incorrect Current Password.",
    LIMIT_EXCEEDED_EXCEPTION: "Your attempts to change password are completed. Please try after sometime.",
    USER_TOKEN_EXPIRED:
      "Link to reset the password is expired. Please click on 'Trouble Logging In?' to reset the password.",
    SELECT_INPUT: "Please select valid inputs",
    ID_TOKEN_EXPIRED: "Session is expired. Please login again.",
    USER_INVALID_INFO: "Process or URL is interrupted. Please try again with valid URL",
    USER_INVALID_TOKEN:
      "New Password has already been updated. If you are facing problem with login, please click on 'Trouble Logging In?' to reset the password.",
    CONFIG_NAME_EXISTS_EXCEPTION: "Configuration already exist with the provided Config Name",
    ADD_LIGHT_FAILED: "Failed to add light. Please try again.",
    INVALID_QTY: "Please enter valid Quantity.",
    INVALID_BXR_FILE: "Please import valid BXR file.",
    INVALID_IMAGE_FILE: "Please upload valid file. Only .png, .svg, .jpeg and .jpg formats are allowed.",
    INVALID_FILE_SIZE: "Image size cannot be exceed 500 * 500 px.",
    DELETE_CONFIG_FAILED: "Failed to delete configuration",
  },
  SUCCESS_MESSAGE: {
    PASSWORD_UPDATED: "Password updated successfully.",
    TROUBLE_LOGIN_EMAIL_SENT: "Email has been sent to your account.",
    RQUEST_ACCESSS: "Request submitted successfully to Whelen.",
    PASSWORD_SET: "Password set successfully.",
    UPDATE_CONFIG: "Configuration updated successfully.",
    CONFIG_CREATED: "Configuration created successfully.",
    DELETE_LIGHT: "Light deleted successfully.",
    ADD_CONFIG: "Configuration save as successfully.",
    COPY_CONFIG: "Configuration shared successfully.",
    DELETE_CONFIG: "Configuration deleted successfully.",
  },
  WARNING_MESSAGE: {
    EMAILID_REQUIRED: "Email Address is Required",
    EMAILID_INVALID: "Please enter valid Email Address (Format abc@xyz.com)",
    EMAILID_MAX_LENGTH: "Please enter valid Email Address (Max 128 character allowed in format abc@xyz.com",
  },
  INFO_MESSAGE: {
    DELETE_LIGHT_CONFM: "Are you sure, you want to remove the light from the commonly used light sources?",
    UNSAVED_CHANGES_CONFM:
      "You have some unsaved changes, would you like to save your current configuration before proceeding?",
    UNSAVED_CONFIG: "Configuration changes are unsaved ",
  },
  SESSION_TIMEOUT: 30, // minutes,
  SESSION_NOTIFICATION_TIMEOUT: 30, // seconds
  ...config,
};
