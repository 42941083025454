import axios from "axios";
import { Subject } from "rxjs";
import configData from "../config/config";
import AuthService from "./AuthService";

const saveMenuOption = new Subject();
const configDetailMenuOption = new Subject();
const saveAsMenuOption = new Subject();
const openMenuOption = new Subject();
const performPrePdfOperations = new Subject();
const newConfOperations = new Subject();
const logoutOperations = new Subject();

const shareConfiguration = new Subject();
const warningMessageStatus = new Subject();
const deleteOperations = new Subject();
const getDelConfig = () => deleteOperations;

const setDeleteConfig = (data) => {
  deleteOperations.next(data);
};
const setWarningMessageStatus = (data) => {
  warningMessageStatus.next(data);
};

const getWarningMessageStatus = () => warningMessageStatus;

const setSaveMenu = (data) => {
  saveMenuOption.next(data);
};
const getSaveMenu = () => saveMenuOption;

const setSaveAsMenu = (data) => {
  saveAsMenuOption.next(data);
};

// Subscription to open the share configuration modal pop up
const setShareConfig = (data) => {
  shareConfiguration.next(data);
};

const getShareConfig = () => shareConfiguration;

const setOpenMenu = (data) => {
  openMenuOption.next(data);
};

const getOpenMenu = () => openMenuOption;
const getSaveAsMenu = () => saveAsMenuOption;

const setConfigDetailMenuOption = (data) => {
  configDetailMenuOption.next(data);
};
const getConfigDetailMenuOption = () => configDetailMenuOption;

const setPerformPrePdfOperations = (data) => {
  performPrePdfOperations.next(data);
};
const getPerformPrePdfOperations = () => performPrePdfOperations;

const setNewConfOperations = (data) => {
  newConfOperations.next(data);
};
const getNewConfOperations = () => newConfOperations;

const setLogoutOperations = (data) => {
  logoutOperations.next(data);
};
const getLogoutOperations = () => logoutOperations;

// Method use to make api request to aws
const getConfigurations = async (pageSize, lastEvaluatedKey = {}, configName = "") => {
  // TODO: Logging framework to be added in future.
  const byUserId = AuthService.getUserId();
  let url = `${configData.API_URL}/configs?byUserId=${encodeURIComponent(byUserId)}&pageSize=${pageSize}`;
  let val = "";
  if (configName && lastEvaluatedKey.id) {
    val = configName;
    url = `${url}&configName=${encodeURIComponent(val)}&id=${lastEvaluatedKey.id}&lastModifiedDate=${
      lastEvaluatedKey.lastModifiedDate
    }&userId=${lastEvaluatedKey.userId}`;
  } else if (lastEvaluatedKey.id) {
    url = `${url}&id=${lastEvaluatedKey.id}&lastModifiedDate=${lastEvaluatedKey.lastModifiedDate}&userId=${lastEvaluatedKey.userId}`;
  } else if (configName) {
    val = configName;
    url = `${url}&configName=${encodeURIComponent(val)}`;
  }
  const res = await axios({
    method: "GET",
    url,
  });
  return res;
};

// method for save config data
const saveConfigurations = async (request) => {
  const url = `${configData.API_URL}/configs`;
  const res = await axios({
    method: "POST",
    data: request,
    url,
  });
  return res;
};

// method for share config data to another user
const shareConfigurations = async (request) => {
  const url = `${configData.API_URL}/configs/migrate`;
  const res = await axios({
    method: "POST",
    data: request,
    url,
  });
  return res;
};

// method for update config data
const updateConfigurations = async (request, id) => {
  const url = `${configData.API_URL}/configs/${encodeURIComponent(id)}`;
  const res = await axios({
    method: "PUT",
    data: request,
    url,
  });
  return res;
};

// method for save config data
const addLightsToConfiguration = async (request) => {
  const lightData = [];
  lightData.push(request);
  const url = `${configData.API_URL}/configs/${encodeURIComponent(request.configId)}/lights`;
  const res = await axios({
    method: "POST",
    data: lightData,
    url,
  });
  return res;
};

// method for update config data
const updateLightsToConfiguration = async (request, configId) => {
  const url = `${configData.API_URL}/configs/${encodeURIComponent(configId)}/lights`;
  const res = await axios({
    method: "PUT",
    data: request,
    url,
  });
  return res;
};

// method for add lights to user preference
const addLightToUserPreference = async (request) => {
  const userId = AuthService.getUserId();
  const url = `${configData.API_URL}/users/${encodeURIComponent(userId)}/lights/${encodeURIComponent(
    request.light_id
  )}`;
  const res = await axios({
    method: "POST",
    data: request,
    url,
  });
  return res;
};

// method for add lights to user preference
const updateLightToUserPreference = async (request) => {
  const userId = AuthService.getUserId();
  const url = `${configData.API_URL}/users/${encodeURIComponent(userId)}/lights`;
  const res = await axios({
    method: "PUT",
    data: request,
    url,
  });
  return res;
};

// method for get lights for the selected configurations.
const getConfigLights = async (request) => {
  const url = `${configData.API_URL}/configs/${encodeURIComponent(request.config_id)}/lights`;
  const res = await axios({
    method: "GET",
    url,
  });
  return res;
};
// method for get config by configuration id.
const getConfigById = async (request) => {
  const url = `${configData.API_URL}/configs/${encodeURIComponent(request.id)}`;
  const res = await axios({
    method: "GET",
    url,
  });
  return res;
};

// method for delete lights from the selected configurations.
const deleteConfigLights = async (request) => {
  const url = `${configData.API_URL}/configs/lights/${encodeURIComponent(request.lightId)}`;
  const res = await axios({
    method: "DELETE",
    url,
  });
  return res;
};

// method for get lights for common preferences
const getCommonPreferenceLights = async (lastEvaluatedKey = {}, token) => {
  const userId = AuthService.getUserId();
  let url;
  if (lastEvaluatedKey.id) {
    url = `${configData.API_URL}/users/${encodeURIComponent(userId)}/lights?isActive=true&isExcluded=false&id=${
      lastEvaluatedKey.id
    }&lastModifiedDate=${lastEvaluatedKey.lastModifiedDate}&userId=${lastEvaluatedKey.userId}`;
  } else {
    url = `${configData.API_URL}/users/${encodeURIComponent(userId)}/lights?isActive=true&isExcluded=false`;
  }
  const res = await axios({
    method: "GET",
    url,
    cancelToken: token,
  }).catch((e) => {
    console.log("Error", e);
  });
  return res;
};

// method for delete lights for common preferences
const deleteCommonPreferenceLights = async (request) => {
  const url = `${configData.API_URL}/users/lights/${encodeURIComponent(request.lightId)}`;
  const res = await axios({
    method: "DELETE",
    url,
  });
  return res;
};

// method for set config data to the session storage
const setConfigToSessionStorage = (data) => {
  if (data.configDetails) {
    sessionStorage.setItem("configDetails", JSON.stringify(data.configDetails));
  }

  if (data.configName) {
    sessionStorage.setItem("configName", JSON.stringify(data.configName));
  }

  if (data.vehicleSize) {
    sessionStorage.setItem("vehicleSize", JSON.stringify(data.vehicleSize));
  }

  if (data.stdName) {
    sessionStorage.setItem("stdName", JSON.stringify(data.stdName));
  }
};

// method for get config data from the session storage
const getConfigDetailsFromStorage = () => {
  if (sessionStorage.key("configDetails")) {
    return JSON.parse(sessionStorage.getItem("configDetails"));
  }
  return "";
};

// method for get config name from the session storage
const getConfigNameFromStorage = () => {
  if (sessionStorage.key("configName")) {
    return JSON.parse(sessionStorage.getItem("configName"));
  }
  return "";
};

// method for get config name from the session storage
const getStdNameFromStorage = () => {
  if (sessionStorage.key("stdName")) {
    return JSON.parse(sessionStorage.getItem("stdName"));
  }
  return "";
};

// method for get vehicle size from  the session storage
const getVehicleSizeFromStorage = () => {
  if (sessionStorage.key("vehicleSize")) {
    return JSON.parse(sessionStorage.getItem("vehicleSize"));
  }
  return "";
};

// method for set mirror feature data to the session storage
const setMirrorZoneDetailsToStorage = (data) => {
  if (data) {
    sessionStorage.setItem("zoneMirrorsData", JSON.stringify(data));
  }
};

// method for get mirror feature data from the session storage
const getMirrorZoneDetailsFromStorage = () => {
  if (sessionStorage.key("zoneMirrorsData")) {
    return JSON.parse(sessionStorage.getItem("zoneMirrorsData"));
  }
  return "";
};

// method for save lights data
const addLightsdataToConfiguration = async (request, configId) => {
  const url = `${configData.API_URL}/configs/${encodeURIComponent(configId)}/lights`;
  const res = await axios({
    method: "POST",
    data: request,
    url,
  });
  return res;
};

// method for upload BXR file data
const uploadBXR = async (request) => {
  const userId = AuthService.getUserId();
  const url = `${configData.API_URL}/lightbars/import`;
  request.userId = userId;
  console.log(request);
  const res = await axios({
    method: "POST",
    data: request,
    url,
  });
  return res;
};
const getSystemTypes = async () => {
  const url = `${configData.API_URL}/standards`;
  const res = await axios({
    method: "GET",
    url,
  });
  return res;
};

// used for get system types from the localstorage
const getStandardListFromLocalStorage = () => {
  if (localStorage.key("standardList")) {
    return JSON.parse(localStorage.getItem("standardList"));
  }
  return null;
};

// used for get system types from the localstorage
const getFooterListFromLocalStorage = () => {
  if (localStorage.key("footerList")) {
    return JSON.parse(localStorage.getItem("footerList"));
  }
  return null;
};

// method for deleting configuration
const deleteConfigurationMethod = async (configId) => {
  const url = `${configData.API_URL}/configs/${encodeURIComponent(configId)}`;
  const res = await axios({
    method: "DELETE",
    url,
  });
  return res;
};

const ConfigService = {
  getConfigurations,
  saveConfigurations,
  shareConfigurations,
  addLightsToConfiguration,
  addLightToUserPreference,
  updateLightToUserPreference,
  getConfigLights,
  deleteConfigLights,
  updateConfigurations,
  setSaveMenu,
  getSaveMenu,
  getCommonPreferenceLights,
  deleteCommonPreferenceLights,
  updateLightsToConfiguration,
  setConfigToSessionStorage,
  getConfigDetailsFromStorage,
  getConfigNameFromStorage,
  getVehicleSizeFromStorage,
  setMirrorZoneDetailsToStorage,
  getMirrorZoneDetailsFromStorage,
  uploadBXR,
  setConfigDetailMenuOption,
  getConfigDetailMenuOption,
  setSaveAsMenu,
  getSaveAsMenu,
  setShareConfig,
  getShareConfig,
  addLightsdataToConfiguration,
  getStdNameFromStorage,
  setOpenMenu,
  getOpenMenu,
  getConfigById,
  setPerformPrePdfOperations,
  getPerformPrePdfOperations,
  setNewConfOperations,
  getNewConfOperations,
  setLogoutOperations,
  getLogoutOperations,
  getSystemTypes,
  getStandardListFromLocalStorage,
  getFooterListFromLocalStorage,
  setWarningMessageStatus,
  getWarningMessageStatus,
  setDeleteConfig,
  getDelConfig,
  deleteConfigurationMethod,
};

export default ConfigService;
