/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/jsx-one-expression-per-line */
// Pdf Components
import React from "react";
import ReactDOM from "react-dom";
import { OP_SYSTEM, ONTARIO, STANDARD, SIZES } from "../../../services/Constant";
import logo from "../../../assets/images/logo.png";

const { detect } = require("detect-browser");

const browser = detect();
class Pdf extends React.Component {
  constructor(props) {
    super(props);
    this.lightbar = React.createRef();
    this.title = React.createRef();
    this.lights = React.createRef();
    this.warningMessage = React.createRef();
    this.state = {
      pdfLogo: logo,
    };
  }

  componentDidMount() {
    const { setLightBarHeight } = this.props;
    const { setLightsHeight } = this.props;
    const { setWarningHeight } = this.props;
    const head = ReactDOM?.findDOMNode(this.title.current)?.getBoundingClientRect();
    const bar = ReactDOM?.findDOMNode(this.lightbar.current)?.getBoundingClientRect();
    const light = ReactDOM?.findDOMNode(this.lights.current)?.getBoundingClientRect();
    const warning = ReactDOM?.findDOMNode(this.warningMessage.current)?.getBoundingClientRect();
    if (light?.top) {
      setLightsHeight(560 - parseInt(light.top - head.top + 55, 10));
    }
    if (bar?.top) {
      setLightBarHeight(parseInt(bar.top - head.top + 55, 10));
    }
    if (warning?.top) {
      setWarningHeight(parseInt(warning.top - head.top + 55, 10));
    }
    const img = new Image();
    img.src = logo;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const context = canvas.getContext("2d");
      context.drawImage(img, 0, 0);
      canvas.toBlob((blob) => {
        this.setState({ pdfLogo: URL.createObjectURL(blob) });
      }, "image/png");
    };
  }

  callingStatement = () => (
    <div>
      Calling
      <span style={{ marginLeft: "0.01rem" }}> </span>
      <span>for</span>
      <span style={{ marginLeft: "0.01rem" }}> </span>
      <span>right</span>
      <span style={{ marginLeft: "0.04rem" }}> </span>
      <span>of</span>
      <span style={{ marginLeft: "0.04rem" }}> </span>
      <span>way</span>
    </div>
  );

  primaryEmergencyCalling = () => (
    <div>
      Primary
      <span style={{ marginLeft: "0.01rem" }}> </span>
      <span>Emergency</span>
    </div>
  );

  blockingStatement = () => (
    <div>
      Blocking
      <span style={{ marginLeft: "0.01rem" }}> </span>
      <span>for</span>
      <span style={{ marginLeft: "0.01rem" }}> </span>
      <span>right</span>
      <span style={{ marginLeft: "0.04rem" }}> </span>
      <span>of</span>
      <span style={{ marginLeft: "0.04rem" }}> </span>
      <span>way</span>
    </div>
  );

  // display available filters and lens color for lightbar
  filterAndLensColorDisplay = (data) => (
    <div>
      {data?.lensColor ? `Lens Color: ${data?.lensColor} ` : null}
      {data?.filterModel ? `\nFilter Model: ${data?.filterModel} ` : null}
      {data?.filterColor ? `\nFilter Color: ${data?.filterColor} ` : null}
    </div>
  );

  lightBarDiv = (light) => {
    const isSafari = browser?.name === "safari";
    return (
      <div style={{ marginBottom: "8px" }} className="page-break">
        <div className="sub-headers-text text-left">
          {`${light.series ? `${light.series} ` : ""}${light.size ? `${light.size} ` : ""}${light.modelNumber}`}
          {` - QTY: ${light.qty}`} <span className="font-wt">&nbsp;&nbsp;ROTATION: </span>
          <span>{light.rotation}</span>
        </div>
        <div className="d-flex lightbar-content">
          <div className="sub-headers-percent" style={!isSafari ? { overflowWrap: "break-word" } : {}}>
            <div className="mr-3">
              <span className="font-wt">FRONT DIRECTIONAL: </span>
              <br />
              {light?.fronDirectional.map((data) => (
                <div className="value">
                  {data?.name}
                  {` - QTY: ${data?.qty}`}
                  {`\n`}
                  {this.filterAndLensColorDisplay(data)}
                </div>
              ))}
            </div>
          </div>
          <div className="sub-headers-percent" style={!isSafari ? { overflowWrap: "break-word" } : {}}>
            <div className="mr-3">
              <span className="font-wt">REAR DIRECTIONAL: </span>
              <br />
              {light?.rearDirectional.map((data) => (
                <div className="value">
                  {data?.name}
                  {` - QTY: ${data?.qty}`}
                  {`\n`}
                  {this.filterAndLensColorDisplay(data)}
                </div>
              ))}
            </div>
          </div>
          <div className="sub-headers-percent" style={!isSafari ? { overflowWrap: "break-word" } : {}}>
            <div className="mr-3 value">
              <span className="font-wt">CORNERS: </span>
              <br />
              {light?.corners.map((data) => (
                <div className="value">
                  {data?.name}
                  {` - QTY: ${data?.qty}`}
                  {`\n`}
                  {this.filterAndLensColorDisplay(data)}
                </div>
              ))}
            </div>
          </div>
          <div className="sub-headers-percent" style={!isSafari ? { overflowWrap: "break-word" } : {}}>
            <div className="mr-3">
              <span className="font-wt">ALLEYS: </span>
              <br />
              {light?.alleys.map((data) => (
                <div className="value">
                  {data?.name}
                  {` - QTY: ${data?.qty}`}
                  {`\n`}
                  {this.filterAndLensColorDisplay(data)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      configDetail,
      finalResultsData,
      lightsData,
      excludedLightsInZone,
      warningDataPdf,
      printWarning,
      opSystem,
      lightBars,
      componentToPrintRef,
      configurationImage,
    } = this.props;
    const isSafari = browser?.name === "safari";
    const isFirefox = browser?.name === "firefox";
    // prepare data for unused zone
    // eslint-disable-next-line array-callback-return
    Object.keys(lightsData).map((zone) => {
      if (lightsData[zone].length === 0 && !excludedLightsInZone?.zone) {
        const key = zone;
        lightsData[key][0] = {
          lightSource: "",
          qty: "",
          obstructed: [],
          currentMax: "",
          currentAverage: "",
        };
      }
    });
    const ZonePeak = (ZoneData) => {
      let zonePeak = 0;
      ZoneData.forEach((element) => {
        const currentMax = parseFloat(element.currentMax * element.qty);
        zonePeak += currentMax;
      });
      return `${zonePeak.toFixed(2)}A`;
    };
    const ZoneAvg = (ZoneData) => {
      let zoneAvg = 0;
      ZoneData.forEach((element) => {
        const currentAvg = parseFloat(element.currentAverage * element.qty);
        zoneAvg += currentAvg;
      });
      return `${zoneAvg.toFixed(2)}A`;
    };

    const breakDetails = (data, length) => {
      if (!isSafari) {
        return data;
      }
      const array = data.match(new RegExp(`.{1,${length}}`, "g")) || [];
      return array.join("\r\n");
    };
    const CalculationSummary = ({ pdfLogo, showConfigLogo }) => (
      <>
        <div className="d-flex mb-3 justify-content-center page-break">
          <div className="title text-center" style={{ marginTop: "0px" }}>
            <img src={pdfLogo.pdfLogo} style={{ width: 206, marginBottom: "15px", height: "auto" }} alt="logo" />
            <div
              className="title"
              style={
                !isSafari
                  ? { fontSize: "19px", textAlign: "center", letterSpacing: "0.3px" }
                  : { fontSize: "19px", textAlign: "center" }
              }
            >
              Calculation Summary
            </div>
          </div>
          {configurationImage && showConfigLogo ? (
            <img
              src={configurationImage.base64Image}
              width={configurationImage.width}
              height={configurationImage.height}
              alt="configuration-logo"
              className="configuration-logo"
            />
          ) : null}
        </div>
        <div className="header-details">
          <div className="d-flex justify-content-center" />
          <div className="d-flex mt-5">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <table className="zone-table-info info-table">
                  <tr>
                    <td>
                      <table>
                        <tr>
                          <th style={{ marginBottom: "1px" }}>CALCULATION DATE: </th>
                          <td
                            className="word-space"
                            style={{
                              marginTop: isSafari ? "1px" : isFirefox && opSystem !== OP_SYSTEM.MAC ? "2px" : "1px",
                            }}
                          >
                            {configDetail.date}
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <table style={{ lineHeight: "2.14" }}>
                        <tr>
                          <th style={{ marginBottom: "1px" }}>STANDARD: </th>
                          <td
                            className="word-space"
                            style={
                              isSafari
                                ? { paddingTop: "0.4rem" }
                                : isFirefox && opSystem !== OP_SYSTEM.MAC
                                ? { overflowWrap: "break-word", maxWidth: "max-content" }
                                : isFirefox && opSystem === OP_SYSTEM.MAC
                                ? { overflowWrap: "break-word", maxWidth: "max-content" }
                                : { overflowWrap: "break-word", maxWidth: "max-content", marginTop: "1px" }
                            }
                          >
                            {configDetail.standard}
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <table style={{ lineHeight: "2.14" }}>
                        <tr>
                          <th style={{ marginBottom: "1px" }}>CREATED BY: </th>
                          {configDetail.user.length < 25 || !isSafari ? (
                            <td
                              className="word-space"
                              style={
                                isSafari
                                  ? { paddingTop: "0.4rem" }
                                  : isFirefox && opSystem !== OP_SYSTEM.MAC
                                  ? { overflowWrap: "break-word", maxWidth: "150px" }
                                  : isFirefox && opSystem === OP_SYSTEM.MAC
                                  ? { overflowWrap: "break-word", maxWidth: "150px", paddingTop: "0px" }
                                  : { overflowWrap: "break-word", maxWidth: "280px", marginTop: "1px" }
                              }
                            >
                              {configDetail.user}
                            </td>
                          ) : (
                            <td
                              className="word-space"
                              style={
                                isSafari
                                  ? { paddingTop: "3px" }
                                  : isFirefox
                                  ? { overflowWrap: "break-word", maxWidth: "130px" }
                                  : { overflowWrap: "break-word", maxWidth: "280px" }
                              }
                            >
                              <span>
                                {breakDetails(configDetail.user.split(" ")[0], 25)} <br />
                                {breakDetails(configDetail.user.split(" ")[1], 25)}
                              </span>
                            </td>
                          )}
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="d-flex md-5 mt-2">
            <div className="r-block">
              <table className="intensity-table-new">
                <tr>
                  <th className="mb-0 font-7 mr-2">SYSTEM TYPE:</th>
                  <th className="mb-0 font-7 mr-2">VEHICLE:</th>
                  <th className="mb-0 font-7 mr-2">VIN:</th>
                  <th className="mb-0 font-7 mr-2">SIZE:</th>
                  <th className="mb-0 font-7 mr-2">DEPT:</th>
                  <th className="mb-0 font-7 mr-2">JOB:</th>
                  <th className="mb-0 font-7 mr-2">CONTRACT:</th>
                  <th className="mb-0 font-7 mr-2">
                    TOTAL<span className="pl-05">CURRENT</span> DRAW:
                  </th>
                </tr>
                <tr>
                  <td style={!isSafari ? { overflowWrap: "break-word" } : {}}>{configDetail.sytemType}</td>
                  <td style={!isSafari ? { overflowWrap: "break-word" } : {}}>
                    {breakDetails(configDetail.vehicle, 7)}
                  </td>
                  <td style={!isSafari ? { overflowWrap: "break-word" } : {}}>{breakDetails(configDetail.vin, 7)}</td>
                  <td>
                    {configDetail?.standard === STANDARD?.ULC515_2024 && configDetail?.size === SIZES?.GREATER_THAN ? (
                      ">7.62m"
                    ) : configDetail?.standard === STANDARD?.ULC515_2024 && configDetail?.size === SIZES?.LESS_THAN ? (
                      "<7.62m"
                    ) : configDetail?.size === SIZES?.GREATER_THAN ? (
                      ">25'"
                    ) : configDetail?.size === SIZES?.LESS_THAN ? (
                      "< 25'"
                    ) : (
                      <>
                        <span>Any</span>
                        <br />
                        <span>Size</span>
                      </>
                    )}
                  </td>
                  <td style={!isSafari ? { overflowWrap: "break-word" } : {}}>
                    {breakDetails(configDetail.department, 7)}
                  </td>
                  <td style={!isSafari ? { overflowWrap: "break-word" } : {}}>{breakDetails(configDetail.job, 7)}</td>
                  <td style={!isSafari ? { overflowWrap: "break-word" } : {}}>
                    {breakDetails(configDetail.contract, 7)}
                  </td>
                  <td>
                    <span>
                      PEAK: {configDetail.currentMax} {configDetail.currentMax === "N/A" ? "" : "A"}
                    </span>
                    <br />
                    AVERAGE: {configDetail.currentAverage} {configDetail.currentAverage === "N/A" ? "" : "A"}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </>
    );
    const getPageMargins = () =>
      browser?.name === "firefox"
        ? `@page { margin: 60 30 15 30 !important; size: A4 }`
        : `@page { margin: 60 30 30 30 !important; size: A4 }`;
    return (
      <div className="pdf mb-0 pdf-content" ref={componentToPrintRef} style={{ lineHeight: "1.8" }}>
        <style>{getPageMargins()}</style>
        <span className="custom-footer-1" />
        <span className="custom-footer-2" />
        <span className="custom-pagination" />
        <div className="page mb-0" ref={this.title}>
          <div className="card-body p-0" id="light-lightbar">
            <div className="initial-page">
              <CalculationSummary pdfLogo={this.state} showConfigLogo />
              {finalResultsData.size ? (
                <div className="mb-5 results-data">
                  <div className="title mt-3" style={!isSafari ? { letterSpacing: "-0.3px" } : {}}>
                    RESULTS
                  </div>
                  <div className="d-flex md-2">
                    <div className="r-block">
                      {finalResultsData.has("zoneacallingupper") ||
                      finalResultsData.has("zoneacalling") ||
                      finalResultsData.has("zoneablockingupper") ||
                      finalResultsData.has("zoneablocking") ? (
                        <table className={`${isSafari ? "intensity-table-safari" : "intensity-table"} mr-3`}>
                          <th className="mb-0">
                            ZONE A{" "}
                            {finalResultsData.has("zoneacallingupper") || finalResultsData.has("zoneablockingupper")
                              ? "UPPER"
                              : ""}
                          </th>
                          {finalResultsData?.has("zoneacallingupper") || finalResultsData.has("zoneacalling") ? (
                            <tr>
                              <td>
                                {configDetail.standard === ONTARIO.NAME
                                  ? this.primaryEmergencyCalling()
                                  : this.callingStatement()}
                              </td>
                              <td className="text-right">
                                <span
                                  className={`text-right ${
                                    finalResultsData.has("zoneacallingupper")
                                      ? finalResultsData.get("zoneacallingupper").result === "Pass"
                                        ? "text-success"
                                        : "text-danger"
                                      : finalResultsData.get("zoneacalling").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {finalResultsData.has("zoneacallingupper")
                                    ? finalResultsData.get("zoneacallingupper").result
                                    : finalResultsData.get("zoneacalling").result}
                                </span>
                              </td>
                            </tr>
                          ) : null}
                          {finalResultsData.has("zoneablockingupper") || finalResultsData.has("zoneablocking") ? (
                            <tr>
                              <td>{this.blockingStatement()}</td>
                              <td className="text-right">
                                <span
                                  className={`text-right ${
                                    finalResultsData.has("zoneablockingupper")
                                      ? finalResultsData.get("zoneablockingupper").result === "Pass"
                                        ? "text-success"
                                        : "text-danger"
                                      : finalResultsData.get("zoneablocking").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {finalResultsData.has("zoneablockingupper")
                                    ? finalResultsData.get("zoneablockingupper").result
                                    : finalResultsData.get("zoneablocking").result}
                                </span>
                              </td>
                            </tr>
                          ) : null}
                        </table>
                      ) : null}
                    </div>
                    <div className="r-block">
                      {finalResultsData.has("zonebcallingupper") ||
                      finalResultsData.has("zonebcalling") ||
                      finalResultsData.has("zonebblockingupper") ||
                      finalResultsData.has("zonebblocking") ? (
                        <table className={`${isSafari ? "intensity-table-safari" : "intensity-table"} mr-3`}>
                          <th className="mb-0">
                            ZONE B{" "}
                            {finalResultsData.has("zonebcallingupper") || finalResultsData.has("zonebblockingupper")
                              ? "UPPER"
                              : ""}
                          </th>
                          {finalResultsData.has("zonebcallingupper") || finalResultsData.has("zonebcalling") ? (
                            <tr>
                              <td>
                                {configDetail.standard === ONTARIO.NAME
                                  ? this.primaryEmergencyCalling()
                                  : this.callingStatement()}
                              </td>
                              <td className="text-right">
                                <span
                                  className={`text-right ${
                                    finalResultsData.has("zonebcallingupper")
                                      ? finalResultsData.get("zonebcallingupper").result === "Pass"
                                        ? "text-success"
                                        : "text-danger"
                                      : finalResultsData.get("zonebcalling").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {finalResultsData.has("zonebcallingupper")
                                    ? finalResultsData.get("zonebcallingupper").result
                                    : finalResultsData.get("zonebcalling").result}
                                </span>
                              </td>
                            </tr>
                          ) : null}
                          {finalResultsData.has("zonebblockingupper") || finalResultsData.has("zonebblocking") ? (
                            <tr>
                              <td>{this.blockingStatement()}</td>
                              <td className="text-right">
                                <span
                                  className={`text-right ${
                                    finalResultsData.has("zonebblockingupper")
                                      ? finalResultsData.get("zonebblockingupper").result === "Pass"
                                        ? "text-success"
                                        : "text-danger"
                                      : finalResultsData.get("zonebblocking").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {finalResultsData.has("zonebblockingupper")
                                    ? finalResultsData.get("zonebblockingupper").result
                                    : finalResultsData.get("zonebblocking").result}
                                </span>
                              </td>
                            </tr>
                          ) : null}
                        </table>
                      ) : null}
                    </div>
                    <div className="r-block">
                      {finalResultsData.has("zoneccallingupper") ||
                      finalResultsData.has("zoneccalling") ||
                      finalResultsData.has("zonecblockingupper") ||
                      finalResultsData.has("zonecblocking") ? (
                        <table className={`${isSafari ? "intensity-table-safari" : "intensity-table"} mr-3`}>
                          <th className="mb-0">
                            ZONE C{" "}
                            {finalResultsData.has("zoneccallingupper") || finalResultsData.has("zonecblockingupper")
                              ? "UPPER"
                              : ""}
                          </th>
                          {finalResultsData.has("zoneccallingupper") || finalResultsData.has("zoneccalling") ? (
                            <tr>
                              <td>
                                {configDetail.standard === ONTARIO.NAME
                                  ? this.primaryEmergencyCalling()
                                  : this.callingStatement()}
                              </td>
                              <td className="text-right">
                                <span
                                  className={`text-right ${
                                    finalResultsData.has("zoneccallingupper")
                                      ? finalResultsData.get("zoneccallingupper").result === "Pass"
                                        ? "text-success"
                                        : "text-danger"
                                      : finalResultsData.get("zoneccalling").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {finalResultsData.has("zoneccallingupper")
                                    ? finalResultsData.get("zoneccallingupper").result
                                    : finalResultsData.get("zoneccalling").result}
                                </span>
                              </td>
                            </tr>
                          ) : null}
                          {finalResultsData.has("zonecblockingupper") || finalResultsData.has("zonecblocking") ? (
                            <tr>
                              <td>{this.blockingStatement()}</td>
                              <td className="text-right">
                                <span
                                  className={`text-right ${
                                    finalResultsData.has("zonecblockingupper")
                                      ? finalResultsData.get("zonecblockingupper").result === "Pass"
                                        ? "text-success"
                                        : "text-danger"
                                      : finalResultsData.get("zonecblocking").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {finalResultsData.has("zonecblockingupper")
                                    ? finalResultsData.get("zonecblockingupper").result
                                    : finalResultsData.get("zonecblocking").result}
                                </span>
                              </td>
                            </tr>
                          ) : null}
                        </table>
                      ) : null}
                    </div>
                    <div className="r-block">
                      {finalResultsData.has("zonedcallingupper") ||
                      finalResultsData.has("zonedcalling") ||
                      finalResultsData.has("zonedblockingupper") ||
                      finalResultsData.has("zonedblocking") ? (
                        <table className={`${isSafari ? "intensity-table-safari" : "intensity-table"} mr-3`}>
                          <th className="mb-0">
                            ZONE D{" "}
                            {finalResultsData.has("zonedcallingupper") || finalResultsData.has("zonedblockingupper")
                              ? "UPPER"
                              : ""}
                          </th>
                          {finalResultsData.has("zonedcallingupper") || finalResultsData.has("zonedcalling") ? (
                            <tr>
                              <td>
                                {configDetail.standard === ONTARIO.NAME
                                  ? this.primaryEmergencyCalling()
                                  : this.callingStatement()}
                              </td>
                              <td className="text-right">
                                <span
                                  className={`text-right ${
                                    finalResultsData.has("zonedcallingupper")
                                      ? finalResultsData.get("zonedcallingupper").result === "Pass"
                                        ? "text-success"
                                        : "text-danger"
                                      : finalResultsData.get("zonedcalling").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {finalResultsData.has("zonedcallingupper")
                                    ? finalResultsData.get("zonedcallingupper").result
                                    : finalResultsData.get("zonedcalling").result}
                                </span>
                              </td>
                            </tr>
                          ) : null}
                          {finalResultsData.has("zonedblockingupper") || finalResultsData.has("zonedblocking") ? (
                            <tr>
                              <td>{this.blockingStatement()}</td>
                              <td className="text-right">
                                <span
                                  className={`text-right ${
                                    finalResultsData.has("zonedblockingupper")
                                      ? finalResultsData.get("zonedblockingupper").result === "Pass"
                                        ? "text-success"
                                        : "text-danger"
                                      : finalResultsData.get("zonedblocking").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {finalResultsData.has("zonedblockingupper")
                                    ? finalResultsData.get("zonedblockingupper").result
                                    : finalResultsData.get("zonedblocking").result}
                                </span>
                              </td>
                            </tr>
                          ) : null}
                        </table>
                      ) : null}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="r-block">
                      {finalResultsData.has("zoneacallinglower") || finalResultsData.has("zoneablockinglower") ? (
                        <table className={`${isSafari ? "intensity-table-safari" : "intensity-table"} mr-3`}>
                          <th className="mb-0">ZONE A LOWER</th>
                          {finalResultsData.has("zoneacallinglower") ? (
                            <tr>
                              <td>
                                {configDetail.standard === ONTARIO.NAME
                                  ? this.primaryEmergencyCalling()
                                  : this.callingStatement()}
                              </td>
                              <td className="text-right">
                                <span
                                  className={`text-right ${
                                    finalResultsData.get("zoneacallinglower").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {finalResultsData.get("zoneacallinglower").result}
                                </span>
                              </td>
                            </tr>
                          ) : null}
                          {finalResultsData.has("zoneablockinglower") ? (
                            <tr>
                              <td>{this.blockingStatement()}</td>
                              <td className="text-right">
                                <span
                                  className={`text-right ${
                                    finalResultsData.get("zoneablockinglower").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {finalResultsData.get("zoneablockinglower").result}
                                </span>
                              </td>
                            </tr>
                          ) : null}
                        </table>
                      ) : null}
                    </div>
                    <div className="r-block">
                      {finalResultsData.has("zonebcallinglower") || finalResultsData.has("zonebblockinglower") ? (
                        <table className={`${isSafari ? "intensity-table-safari" : "intensity-table"} mr-3`}>
                          <th className="mb-0">ZONE B LOWER</th>
                          {finalResultsData.has("zonebcallinglower") ? (
                            <tr>
                              <td>
                                {configDetail.standard === ONTARIO.NAME
                                  ? this.primaryEmergencyCalling()
                                  : this.callingStatement()}
                              </td>
                              <td className="text-right">
                                <span
                                  className={`text-right ${
                                    finalResultsData.get("zonebcallinglower").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {finalResultsData.get("zonebcallinglower").result}
                                </span>
                              </td>
                            </tr>
                          ) : null}
                          {finalResultsData.has("zonebblockinglower") ? (
                            <tr>
                              <td>{this.blockingStatement()}</td>
                              <td className="text-right">
                                <span
                                  className={`text-right ${
                                    finalResultsData.get("zonebblockinglower").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {finalResultsData.get("zonebblockinglower").result}
                                </span>
                              </td>
                            </tr>
                          ) : null}
                        </table>
                      ) : null}
                    </div>
                    <div className="r-block">
                      {finalResultsData.has("zoneccallinglower") || finalResultsData.has("zonecblockinglower") ? (
                        <table className={`${isSafari ? "intensity-table-safari" : "intensity-table"} mr-3`}>
                          <th className="mb-0">ZONE C LOWER</th>
                          {finalResultsData.has("zoneccallinglower") ? (
                            <tr>
                              <td>
                                {configDetail.standard === ONTARIO.NAME
                                  ? this.primaryEmergencyCalling()
                                  : this.callingStatement()}
                              </td>
                              <td className="text-right">
                                <span
                                  className={`text-right ${
                                    finalResultsData.get("zoneccallinglower").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {finalResultsData.get("zoneccallinglower").result}
                                </span>
                              </td>
                            </tr>
                          ) : null}
                          {finalResultsData.has("zonecblockinglower") ? (
                            <tr>
                              <td>{this.blockingStatement()}</td>
                              <td className="text-right">
                                <span
                                  className={`text-right ${
                                    finalResultsData.get("zonecblockinglower").result === "Pass"
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {finalResultsData.get("zonecblockinglower").result}
                                </span>
                              </td>
                            </tr>
                          ) : null}
                        </table>
                      ) : null}
                    </div>
                    <div className="r-block">
                      {finalResultsData.has("zonedcallinglower") || finalResultsData.has("zonedblockinglower") ? (
                        <table className={`${isSafari ? "intensity-table-safari" : "intensity-table"} mr-3`}>
                          <th className="mb-0">ZONE D LOWER</th>
                          {finalResultsData.has("zonedcallinglower") ? (
                            <tr>
                              <td>
                                {configDetail.standard === ONTARIO.NAME
                                  ? this.primaryEmergencyCalling()
                                  : this.callingStatement()}
                              </td>
                              <td className="text-right">
                                <span
                                  className={`text-right ${
                                    finalResultsData.get("zonedcallinglower").result === "Pass"
                                      ? "text-success"
                                      : "text-danger text-danger-padding"
                                  }`}
                                >
                                  {finalResultsData.get("zonedcallinglower").result}
                                </span>
                              </td>
                            </tr>
                          ) : null}
                          {finalResultsData.has("zonedblockinglower") ? (
                            <tr>
                              <td>{this.blockingStatement()}</td>
                              <td className="text-right">
                                <span
                                  className={`text-right ${
                                    finalResultsData.get("zonedblockinglower").result === "Pass"
                                      ? "text-success"
                                      : "text-danger text-danger-padding"
                                  }`}
                                >
                                  {finalResultsData.get("zonedblockinglower").result}
                                </span>
                              </td>
                            </tr>
                          ) : null}
                        </table>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
              {Object.keys(lightsData).length ? (
                <div className="d-flex mb-3 zone-table-data">
                  <div>
                    <table className="zone-table my-5">
                      <tr ref={this.lights}>
                        <th>{lightsData.length ? " " : " "}</th>
                        <th>
                          <span>LIGHT</span>
                          <br />
                          <span>SOURCE</span>
                        </th>
                        <th>QTY</th>
                        <th className="pad-left">
                          <span>ZONE</span>
                          <br /> <span>PEAK</span>
                        </th>
                        <th>
                          <span>ZONE</span>
                          <br />
                          <span>AVG</span>
                        </th>
                        <th>OBSTRUCTED</th>
                        <th>ROTATION</th>
                        <th>
                          CALLING <br /> INTENSITY
                        </th>
                        {configDetail.standard !== "OPLA & ERV 5.0" ? (
                          <th>
                            BLOCKING <br /> INTENSITY
                          </th>
                        ) : null}
                      </tr>

                      {Object.keys(lightsData).map((zone) =>
                        lightsData[zone].map((light, ind) => (
                          <>
                            <tr className={`page-break ${light.excluded ? "strikeout" : ""}`}>
                              <th>
                                {ind === 0 ? (zone.includes("single") ? zone.split("single")[0].trim() : zone) : ""}
                              </th>
                              <td className="sub-headers-draw" style={!isSafari ? { overflowWrap: "break-word" } : {}}>
                                {light?.lightSource && light.color
                                  ? `${light?.lightSource}-${light.color}`
                                  : light?.lightSource
                                  ? light?.lightSource
                                  : ""}
                              </td>
                              <td>{light?.displayQty ? light.qty : ""}</td>
                              <td className="pad-left">{ind === 0 && light.qty ? ZonePeak(lightsData[zone]) : ""}</td>
                              <td>{ind === 0 && light.qty ? ZoneAvg(lightsData[zone]) : ""}</td>
                              <td>{light.obstructed.toString()}</td>
                              <td>{light.rotation}</td>
                              <td style={!isSafari ? { overflowWrap: "break-word" } : {}}>
                                {light.call_intensity ? light.call_intensity : ""}
                                {configDetail.sytemType !== "Standalone Lights" &&
                                light.call_intensity &&
                                light.call_intensity !== "Steady" &&
                                light.call_intensity !== "0%"
                                  ? "%"
                                  : ""}
                              </td>
                              {configDetail.standard !== "OPLA & ERV 5.0" ? (
                                <td style={!isSafari ? { overflowWrap: "break-word" } : {}}>
                                  {light.block_intensity ? light.block_intensity : ""}
                                  {configDetail.sytemType !== "Standalone Lights" &&
                                  light.block_intensity &&
                                  light.block_intensity !== "Steady" &&
                                  light.block_intensity !== "0%"
                                    ? "%"
                                    : ""}
                                </td>
                              ) : null}
                            </tr>
                            {"importedBxrFileName" in light && light.importedBxrFileName !== null ? (
                              <tr>
                                <td>&nbsp;</td>
                                <td colSpan="8">
                                  <small>File Name : {light?.importedBxrFileName}</small>
                                </td>
                              </tr>
                            ) : null}
                          </>
                        ))
                      )}
                    </table>
                  </div>
                </div>
              ) : null}
            </div>
            <div ref={this.lightbar} />
            <div className="lightbar-data">
              {lightBars.map((lightbar, lightbarindex) => (
                <div
                  style={{
                    paddingTop: "14px",
                    overflowWrap: isSafari ? "" : "break-word",
                    lineHeight: "1.75",
                  }}
                  className={`word-space font-6 page-break lightbar-counter-${lightbarindex}`}
                >
                  {lightbarindex === 0 ? (
                    <div className="mb-2">
                      <div
                        className="title mb-1"
                        style={!isSafari ? { letterSpacing: "0.3px" } : { fontSize: "20px", letterSpacing: "0.3px" }}
                      >
                        LIGHTBARS
                      </div>
                      <div
                        className="text-left font-10 mb-4"
                        style={
                          !isSafari ? { overflowWrap: "break-word", letterSpacing: "0.3" } : { letterSpacing: "0.3" }
                        }
                      >
                        <sup>*</sup>
                        See addendum for lightbar layout
                      </div>
                    </div>
                  ) : null}
                  {this.lightBarDiv(lightbar, lightbarindex)}
                  <span className={`lightbar-page-${lightbarindex}`} />
                </div>
              ))}
            </div>
          </div>
          <div className="text-center font-4 cust-footer footer-1" style={{ height: "10px" }}>
            <span className={lightBars.length > 0 ? "lightbar-page" : "light-pagination"} height="0px" />
            <div className="footerText" style={!isSafari ? { overflowWrap: "break-word" } : {}}>
              {configDetail.footerText}
            </div>
          </div>
          <div ref={this.warningMessage} />
          {printWarning ? (
            <div className="custom-page-break" style={{ pageBreakAfter: "always", breakAfter: "always" }} />
          ) : null}
          {printWarning ? (
            <div id="warningPage">
              <div className="warningHeaderContent">
                <CalculationSummary pdfLogo={this.state} />
              </div>
              <div
                style={{
                  paddingTop: "15px",
                  overflowWrap: isSafari ? "" : "break-word",
                  lineHeight: "1.65",
                }}
                className="word-space"
              >
                <div className="warningContent" id="warningContent" style={{ minHeight: "800px" }}>
                  <div className="mb-1">
                    <div className="title mb-0" style={{ letterSpacing: "-0.4px", fontSize: "22px" }}>
                      Warnings
                    </div>
                  </div>
                  <div>
                    <table className="warningRows">
                      <thead> </thead>
                      {warningDataPdf.map((light) => (
                        <tr className="warning-row">
                          <td>
                            <span className="page-break">
                              {`${light.lightSource} ${light.displayName} in ${light.zone} - ${light.zoneDetail}`}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
              <div className="text-center font-4 cust-footer footer-2">
                <span className="warning-pagination" height="0" />
                <div className="footerText fn" style={!isSafari ? { overflowWrap: "break-word" } : {}}>
                  {configDetail.footerText}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Pdf;
