// Main Component
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import Modal from "react-bootstrap/Modal";
import { withRouter } from "react-router";

import Login from "./Auth/Login";
import HomePage from "./Common/HomePage";
import ZonePage from "./Pages/ZoneManagement/ZonePage";
import authService from "../services/AuthService";
// eslint-disable-next-line no-unused-vars
import AuthInterceptor from "../interceptors/AuthInterceptor";
import SetPassword from "./Auth/SetPassowrd";
import NotFound from "./Common/NotFound";
import SessionTimeoutModal from "./Common/SessionTimeoutModal";
import Logout from "../helper/logout";

import config from "../config/config";

class MainController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTimeoutModal: false,
      isLogout: false,
    };
    this.sessionTimer = null;
    this.logoutTimer = null;
  }

  // Added event listener to notify any change in localstorage varible value change
  componentDidMount() {
    window.onpopstate = () => null;
    if (typeof window !== "undefined") {
      window.addEventListener("storage", (event) => {
        const { history } = this.props;
        const page = JSON.parse(JSON.stringify(window.location.href.split("/").pop()));
        if (event.key === "loggedIn" && event.newValue === "false") {
          this.resetAll();
          history.replace("/login");
          window.onpopstate = () => {
            history.go(1);
          };
          if (page !== "" && !page.includes("login")) window.location.reload();
        }
      });
      window.addEventListener("mouseover", () => {
        const { showTimeoutModal } = this.state;
        if (authService?.isUserLoggedin() && !showTimeoutModal && !this.logoutTimer) {
          this.preSessionStart();
        }
      });
      window.addEventListener("keydown", () => {
        const { showTimeoutModal } = this.state;
        if (authService?.isUserLoggedin() && !showTimeoutModal && !this.logoutTimer) {
          this.preSessionStart();
        }
      });
    }
  }

  // User clicks on Log out button
  onSignOut = () => <Logout />;

  resetAll = () => {
    clearTimeout(this.sessionTimer);
    clearTimeout(this.logoutTimer);
    this.setState({ showTimeoutModal: false });
  };

  startSession = () => {
    if (authService.isUserLoggedin()) {
      const time = config.SESSION_TIMEOUT * 60000;
      this.sessionTimer = setTimeout(() => {
        if (authService.isUserLoggedin()) {
          this.setState({ showTimeoutModal: true });
        }
      }, time);
    } else {
      this.resetAll();
    }
  };

  resetTimer = () => {
    this.resetAll();
    this.startSession();
  };

  preSessionStart = () => {
    const { showTimeoutModal } = this.state;
    if (!showTimeoutModal && !this.logoutTimer) {
      this.resetTimer();
    } else {
      this.setState({ showTimeoutModal: false });
    }
  };

  onResetSession = () => {
    this.setState({ showTimeoutModal: false });
    this.resetTimer();
  };

  onCancleSession = (remainingCount) => {
    if (remainingCount) {
      const timeRemaining = remainingCount * 1000;
      this.logoutTimer = setTimeout(() => {
        this.setState({ isLogout: true });
        this.resetAll();
      }, timeRemaining);
    }
    if (remainingCount === 0) {
      this.resetAll();
      this.setState({ isLogout: true });
    }
    this.setState({ showTimeoutModal: false });
  };

  // Check weather user is logged in or not, before giving access to any API request
  requireLogin = (to, from, next) => {
    if (to.meta.auth) {
      if (authService.isUserLoggedin()) {
        this.preSessionStart();
        next();
      } else {
        this.resetAll();
        next.redirect("/login");
      }
    } else {
      next();
    }
  };

  render() {
    const { showTimeoutModal, isLogout } = this.state;

    return (
      <Router>
        <GuardProvider guards={[this.requireLogin]}>
          <Switch>
            <GuardedRoute component={Login} exact path="/" />
            <GuardedRoute component={Login} exact path="/login" />
            <GuardedRoute component={HomePage} exact path="/home" meta={{ auth: true }} />
            <GuardedRoute component={ZonePage} exact path="/zone" meta={{ auth: true }} />
            <GuardedRoute component={SetPassword} exact path="/users/setpassword" />
            <GuardedRoute
              exact
              path="*"
              render={() => (authService.isUserLoggedin() ? <NotFound /> : <Redirect to="/login" />)}
            />
            <GuardedRoute>
              <main />
            </GuardedRoute>
          </Switch>
        </GuardProvider>
        <Modal className="sessionModal" backdrop="static" show={showTimeoutModal} onHide={this.onCancleSession}>
          <SessionTimeoutModal onResetSession={this.onResetSession} onCancleSession={this.onCancleSession} />
        </Modal>
        {isLogout ? this.onSignOut() : null}
      </Router>
    );
  }
}
export default withRouter(MainController);
