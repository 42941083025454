// Header Component
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ConfigService from "../../services/ConfigService";
import ChangePassword from "../Auth/ChangePassword";
import PdfPopupModal from "./PdfPopupModal";

// function use to dispaly link for zone page
function ZonepageLink({ closeMenu }) {
  const history = useHistory();

  const onSave = () => {
    ConfigService.setSaveMenu(true);
    closeMenu();
  };
  const onConfigDetails = () => {
    ConfigService.setConfigDetailMenuOption(true);
    closeMenu();
  };
  const onSaveAs = () => {
    ConfigService.setSaveAsMenu(true);
    closeMenu();
  };

  const onOpen = () => {
    ConfigService.setOpenMenu(true);
    closeMenu();
  };
  const onNew = () => {
    ConfigService.setNewConfOperations(true);
    closeMenu();
  };
  const onShareConfig = () => {
    ConfigService.setShareConfig(true);
    closeMenu();
  };

  return history.location.pathname === "/zone" ? (
    <div>
      <a href="#" onClick={onNew}>
        New
      </a>
      <a href="#" onClick={onOpen}>
        Open
      </a>
      <a href="#" onClick={onSave}>
        Save
      </a>
      <a href="#" onClick={onSaveAs}>
        Save A Copy
      </a>
      <a href="#" onClick={onShareConfig}>
        Share Configuration
      </a>
      <a href="#" onClick={onConfigDetails}>
        Configuration Details
      </a>
    </div>
  ) : null;
}

// function use to dispaly link for zone page
function DeleteMenuLink({ closeMenu }) {
  const history = useHistory();

  const onDeleteConfig = () => {
    ConfigService.setDeleteConfig(true);
    closeMenu();
  };

  return history.location.pathname === "/zone" ? (
    <div>
      <a href="#" onClick={onDeleteConfig}>
        Delete
      </a>
    </div>
  ) : null;
}

export default () => {
  // const [showing, setShow] = useState(false);
  const [width, setWidth] = useState(0);
  const [openModal, setModalVisibility] = useState(false);
  const [pageName, setPageName] = useState("");
  const node = useRef();
  const [openPdfPopup, setOpenPdfPopup] = useState(false);

  // used for handle click event
  const closeMenu = () => {
    setWidth(0);
  };
  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside menu click
      return;
    }
    // outside menu click
    closeMenu();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  // used for open change-password modal
  const openChangePasswordModal = () => {
    setModalVisibility(true);
    const page = JSON.parse(JSON.stringify(window.location.href.split("/").pop()));
    setPageName(page);
    closeMenu();
  };

  // used for close change-password modal
  const closeChangePasswordModal = () => {
    setModalVisibility(false);
  };

  const onSignOut = () => {
    ConfigService.setLogoutOperations(true);
    closeMenu();
  };

  const onClickViewPdf = () => {
    closeMenu();
    setOpenPdfPopup(true);
  };

  const onClickClosePdf = () => {
    setOpenPdfPopup(false);
  };

  return (
    <div ref={node} className="toggle-button">
      <a
        href="#"
        onClick={() => {
          setWidth(240);
        }}
      >
        <i className="fa fas fa-bars text-white" />
      </a>
      <div style={{ width }} id="mySidenavbar" className="sidenavbar">
        <a
          href="#"
          className="closebtn"
          onClick={() => {
            setWidth(0);
          }}
        >
          <i className="fa fa-bars" />
        </a>
        <ZonepageLink closeMenu={closeMenu} />
        <a href="https://www.whelen.com/faqs/" rel="noreferrer" target="_blank">
          FAQ
        </a>
        <a href="#" onClick={openChangePasswordModal}>
          Change Password
        </a>
        <a rel="noreferrer" target="_blank" href="https://xd.adobe.com/view/da755f26-b5cb-4bf0-8b1c-47110a3d32a8-8f26/">
          AOG Tutorial
        </a>
        <a href="#" onClick={() => onClickViewPdf()}>
          End User License Agreement
        </a>
        <DeleteMenuLink closeMenu={closeMenu} />
        <a href="#" onClick={() => onSignOut()}>
          Logout
        </a>
      </div>
      <Modal
        className={`changepassword-modal ${pageName.includes("home") ? "" : "margin-lft"}`}
        centered
        backdrop="static"
        keyboard={false}
        show={openModal}
        onHide={closeChangePasswordModal}
      >
        <ChangePassword closeChangePasswordModal={closeChangePasswordModal} />
      </Modal>
      <Modal
        className="pdf-popup-modal"
        backdrop="static"
        keyboard={false}
        show={openPdfPopup}
        onHide={onClickClosePdf}
      >
        <PdfPopupModal onClickClosePdf={onClickClosePdf} />
      </Modal>
    </div>
  );
};
