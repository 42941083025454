// NotFound Component
import React from "react";
import { useHistory } from "react-router-dom";
import WhelenRImg from "../../assets/images/W-Red.svg";

const NotFound = () => {
  const history = useHistory();

  const handleBack = () => {
    history.push("/home");
  };

  return (
    <>
      <div class="not-found-page">
        <div class="not-found-wrapper fluid-wrapper ">
          <img alt="Whelen" src={WhelenRImg} className="logo mb-4 pb-2" />
          <h1 class="not-found-title">404</h1>
          <h4 class="not-found-message">The page you requested could not be found.</h4>
          <button type="button" class="back-button btn btn-primary" onClick={handleBack}>
            <i class="fa fa-arrow-left left-arrow-icon" />
            BACK TO HOME
          </button>
        </div>
        <div class="not-found-footer">
          <footer class="footer pt-3">
            Copyright ©2024
            <a href="https://www.whelen.com/" className="mx-2" rel="noreferrer" target="_blank">
              Whelen Engineering.
            </a>
            All rights reserved.
          </footer>
        </div>
      </div>
    </>
  );
};

export default NotFound;
