import { toast } from "react-toastify";
import configService from "../services/ConfigService";
import Config from "../config/config";
// display notification
const displayNotification = (message, type) => {
  toast(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type,
    className: "toaster",
  });
};

// function for display error message
const displayErrorMessage = (errorObject) => {
  const { ERROR_MESSAGE } = Config;
  if (errorObject && errorObject?.response) {
    const errorResponse = errorObject?.response;
    if (errorResponse?.status.toString().startsWith("5")) {
      displayNotification(ERROR_MESSAGE.SOMETHING_WENT_WRONG, "error");
    } else {
      const ERROR_MESSAGE_CODE = errorResponse?.data?.ERROR_MESSAGE_CODE;
      if (ERROR_MESSAGE_CODE && ERROR_MESSAGE[ERROR_MESSAGE_CODE]) {
        displayNotification(ERROR_MESSAGE[ERROR_MESSAGE_CODE], "error");
      } else if (ERROR_MESSAGE_CODE) {
        displayNotification(errorResponse?.data?.MESSAGE, "error");
      } else {
        displayNotification(ERROR_MESSAGE.SOMETHING_WENT_WRONG, "error");
      }
    }
  } else {
    displayNotification(ERROR_MESSAGE.SOMETHING_WENT_WRONG, "error");
  }
};

const getDropDownStyle = (isDisabled) => {
  const style = {
    control: (base) => ({
      ...base,
      border: "0 !important",
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important",
      },
      height: 50,
      minHeight: 50,
      backgroundColor: isDisabled ? "dimgrey" : "white",
      color: isDisabled ? "white" : "black",
      cursor: isDisabled ? "not-allowed" : "pointer",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 2,
      backgroundColor: "white",
      color: "black",
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };
  return style;
};
const getDropDownStyleConfigDetails = (isDisabled) => {
  const style = {
    control: (base) => ({
      ...base,
      border: "0 !important",
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important",
      },
      height: 35,
      minHeight: 35,
      backgroundColor: isDisabled ? "dimgrey" : "white",
      color: isDisabled ? "white" : "black",
      cursor: isDisabled ? "not-allowed" : "pointer",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 2,
      backgroundColor: "white",
      color: "black",
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };
  return style;
};

const getDropDownStyleForSearch = () => {
  const style = {
    control: (base) => ({
      ...base,
      border: "0 !important",
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important",
      },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 2,
      left: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };
  return style;
};

const getDropDownStyleZone = (isDisabled) => {
  const style = {
    control: (base) => ({
      ...base,
      border: "0 !important",
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important",
      },
      height: 25,
      minHeight: 25,
      fontSize: 12,
      backgroundColor: isDisabled ? "dimgrey" : "white",
      color: isDisabled ? "white" : "black",
      cursor: isDisabled ? "not-allowed" : "pointer",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 2,
      backgroundColor: "white",
      color: "black",
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    valueContainer: (base) => ({
      ...base,
      marginTop: -3,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };
  return style;
};
const getDropDownStyleZoneRtnAbs = (isDisabled) => {
  const style = {
    control: (base) => ({
      ...base,
      border: "0 !important",
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important",
      },
      height: 23,
      minHeight: 23,
      fontSize: 12,
      backgroundColor: isDisabled ? "dimgrey" : "white",
      color: isDisabled ? "white" : "black",
      cursor: isDisabled ? "not-allowed" : "pointer",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 2,
      left: 0,
    }),
    valueContainer: (base) => ({
      ...base,
      marginTop: -6,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: () => ({
      marginTop: -6,
    }),
  };
  return style;
};

// Calculate avg value for given zone
const getCurrentAverage = (data, zone, level) => {
  let avg = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const [, value] of Object.entries(data)) {
    // eslint-disable-next-line no-loop-func
    value.forEach((lgt) => {
      if (lgt.zone === zone && lgt.level === level) {
        if (lgt.currentAverage) {
          avg += Number(lgt.currentAverage) * Number(lgt.quantity);
        }
      }
    });
  }
  return avg ? parseFloat(avg).toFixed(2) : "N/A";
};

// Calculate max value for given zone
const getCurrentMax = (data, zone, level) => {
  let max = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const [, value] of Object.entries(data)) {
    // eslint-disable-next-line no-loop-func
    value.forEach((lgt) => {
      if (lgt.zone === zone && lgt.level === level) {
        if (lgt?.currentMax) {
          max += Number(lgt.currentMax) * Number(lgt.quantity);
        }
      }
    });
  }
  return max ? parseFloat(max).toFixed(2) : "N/A";
};

// Calculate total avg value for given zone
const getAllCurrentAverage = (data) => {
  let avg = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const [, value] of Object.entries(data)) {
    // eslint-disable-next-line no-loop-func
    value.forEach((lgt) => {
      if (lgt.currentAverage) {
        avg += Number(lgt.currentAverage) * Number(lgt.quantity ? lgt.quantity : lgt.qty);
      }
    });
  }
  return avg ? parseFloat(avg).toFixed(2) : "N/A";
};

// Calculate total max value for given zone
const getAllCurrentMax = (data) => {
  let max = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const [, value] of Object.entries(data)) {
    // eslint-disable-next-line no-loop-func
    value.forEach((lgt) => {
      if (lgt?.currentMax) {
        max += Number(lgt.currentMax) * Number(lgt.quantity ? lgt.quantity : lgt.qty);
      }
    });
  }
  return max ? parseFloat(max).toFixed(2) : "N/A";
};
// Convert epoch into local date with format "MM/DD/YY HH:MM"
const formatDate = (epoch) => {
  const date = new Date(epoch);
  const yy = parseInt(date.getFullYear().toString().substr(2, 2), 10);
  const rawDate = date.toLocaleString("en-US", { hour12: false }).replace(",", "").split(" ");
  const timePart = `${rawDate[1].split(":")[0]}:${rawDate[1].split(":")[1]}`;
  const mm = rawDate[0].split("/")[0].length === 1 ? `0${rawDate[0].split("/")[0]}` : rawDate[0].split("/")[0];
  const dd = rawDate[0].split("/")[1].length === 1 ? `0${rawDate[0].split("/")[1]}` : rawDate[0].split("/")[1];
  const datePart = `${mm}/${dd}/${yy}`;
  return `${datePart} ${timePart}`;
};

// Convert epoch into local date with format "MM/DD/YYYY"
const getDateForPDF = (epoch) => {
  const date = new Date(epoch);
  const rawDate = date.toLocaleString("en-US", { hour12: false }).replace(",", "").split(" ");
  return rawDate[0];
};

const getStdLabel = (value) => {
  const stdList = configService.getStandardListFromLocalStorage();
  const standard = stdList.filter((std) => std.value === value);
  return standard.length ? standard[0].label : value;
};
const getSysTypeLabel = (value) => {
  const sysTypeList = {
    STANDALONE_LIGHTS: "Standalone Lights",
    LIGHTING_CONTROL_SYSTEM: "Lighting Control System",
  };
  return sysTypeList[value];
};
const getfooterText = (value) => {
  const footerList = configService.getFooterListFromLocalStorage();
  const footer = footerList.filter((text) => text.value === value);
  return footer.length ? footer[0].label : value;
};
const getPageSizes = [
  { value: 10, label: "10" },
  { value: 20, label: "20" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

const getDropDownStyleForPage = () => {
  const style = {
    control: (base) => ({
      ...base,
      border: "0 !important",
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important",
      },
      height: 25,
      minHeight: 25,
      cursor: "pointer",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 2,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    valueContainer: (base) => ({
      ...base,
      marginTop: -5,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: () => ({
      marginTop: -5,
    }),
  };
  return style;
};
const Helpers = {
  displayNotification,
  displayErrorMessage,
  getDropDownStyle,
  getDropDownStyleForSearch,
  getDropDownStyleZone,
  getDropDownStyleZoneRtnAbs,
  getCurrentAverage,
  getCurrentMax,
  getDropDownStyleConfigDetails,
  getAllCurrentAverage,
  getAllCurrentMax,
  formatDate,
  getDateForPDF,
  getStdLabel,
  getPageSizes,
  getDropDownStyleForPage,
  getSysTypeLabel,
  getfooterText,
};

export default Helpers;
