// component for display zone sidebar
import React, { useState, useEffect, useMemo, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { Droppable } from "react-beautiful-dnd";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { AccordionContext, Card } from "react-bootstrap";
import WhelenWImg from "../../../assets/images/logo.png";
import HeaderComponent from "../../Common/ZoneHeader";
import MySources from "./MySources";
import CommonlySource from "./CommonLights";

import LightModal from "./LightModal";
import ZoneResultPage from "./ZoneResult";
import ZoneResultPageMoz from "./zoneResultMoz";
import { LIGHT_TYPES } from "../../../services/Constant";

const { detect } = require("detect-browser");

const browser = detect();

// Accordion Toggle
function ContextAccordionToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      className={`card-header mb-0 ${isCurrentEventKey ? "active" : ""}`}
      onClick={decoratedOnClick}
      role="button"
      tabindex="0"
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          decoratedOnClick();
        }
      }}
    >
      <i className="fa fa-chevron-right" />
      {children}
    </div>
  );
}

const ZoneSidebarPage = ({
  history,
  lightsData,
  mySources,
  commonlyUsed,
  setMySources,
  deleteLightsFromPreference,
  deleteFromMysources,
  isResultAvailable,
  closePerformCalculationResult,
  performCalculationData,
  printWarning,
}) => {
  const [isOpenModal, setAddLightModal] = useState(false);
  const [filteredLightData, setFilteredLightData] = useState([]);

  // To get the lightTypes
  const getLightTypes = (sources) => {
    if (!Array.isArray(sources) || sources.length === 0) {
      return [];
    }
    return [...new Set(sources?.map((source) => source.lightType))].sort();
  };

  const myLightSourceType = useMemo(() => getLightTypes(mySources), [mySources]);

  const openAddLightSourceModal = () => {
    setAddLightModal(true);
  };

  const closeAddLightSourceModal = () => {
    setAddLightModal(false);
  };

  const getFilteredLights = () => {
    const filteredLights = [];
    JSON.parse(JSON.stringify(lightsData)).forEach((element) => {
      if (element.lightType === "Lightbar" && element.isActive === true) {
        filteredLights.push(element);
      } else if (element.lightType !== "Lightbar" && element.isActive === true && element.excluded === false) {
        filteredLights.push(element);
      } else if (element.lightType === "Specialty" && element.isActive === true) {
        filteredLights.push(element);
      }
    });
    setFilteredLightData(filteredLights);
  };

  useEffect(async () => {
    getFilteredLights();
  }, [lightsData]);

  return (
    <div className="sidebar">
      <HeaderComponent history={history} />
      {isResultAvailable ? (
        browser.name === "firefox" ? (
          <ZoneResultPageMoz
            performCalculationData={performCalculationData}
            closePerformCalculationResult={closePerformCalculationResult}
            printWarning={printWarning}
          />
        ) : (
          <ZoneResultPage
            performCalculationData={performCalculationData}
            closePerformCalculationResult={closePerformCalculationResult}
            printWarning={printWarning}
          />
        )
      ) : (
        <div className="zone-sidebar">
          <div className="sidebar-header">
            <a href="#" onClick={openAddLightSourceModal}>
              <h2 className="text-white text-uppercase">
                <i className="fa fa-plus" style={{ color: "#E62629" }} />
                Add a Light Source
              </h2>
            </a>
          </div>
          <div className="sidebar-content">
            <div className="source-list">
              <h3 className="text-white text-uppercase my-source-title">My Light Sources</h3>
              <div className="my-sources-light-source d-flex justify-content-between flex-column overflow-auto">
                <Droppable droppableId="column-1" type="TASK">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps} isDraggingOver={snapshot.isDraggingOver}>
                      <div
                        className={`my-sources-light-source-list  ${mySources?.length > 0 ? "custom-accordion" : ""}`}
                      >
                        {myLightSourceType?.length > 0 &&
                          myLightSourceType.map((lightType) => (
                            <Accordion key={lightType}>
                              <Card>
                                <ContextAccordionToggle className="card-header" eventKey={lightType}>
                                  {LIGHT_TYPES[lightType.toUpperCase()] || lightType.toUpperCase()}
                                </ContextAccordionToggle>
                                <Accordion.Collapse eventKey={lightType}>
                                  <Card.Body>
                                    {mySources
                                      ?.map((source, indx) => ({ ...source, index: indx }))
                                      ?.filter((source) => source.lightType === lightType)
                                      ?.sort((a, b) =>
                                        a?.sDisplayName?.localeCompare(b.sDisplayName, undefined, {
                                          numeric: true,
                                          sensitivity: "base",
                                        })
                                      )
                                      ?.map((source) => (
                                        <MySources
                                          key={source.id}
                                          source={source}
                                          index={source.index}
                                          deleteFromMysources={deleteFromMysources}
                                        />
                                      ))}
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                          ))}
                      </div>
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
            <div className="used-light-source d-flex flex-column">
              <div className="used-light-source-title d-flex justify-content-between align-items-center">
                <h3 className="text-white text-uppercase mb-0">My Saved Light Sources</h3>
              </div>
              <Droppable droppableId="commonlyUsedLights" type="TASK">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="h-100 overflow-auto"
                    isDraggingOver={snapshot.isDraggingOver}
                  >
                    <div className={`used-light-source-list ${commonlyUsed?.length > 0 ? "custom-accordion" : ""}`}>
                      {commonlyUsed?.length > 0 &&
                        [...new Set(commonlyUsed?.map((source) => source.lightType))].sort().map((lightType) => (
                          <Accordion key={lightType}>
                            <Card>
                              <ContextAccordionToggle eventKey={lightType}>
                                {LIGHT_TYPES[lightType.toUpperCase()] || lightType.toUpperCase()}
                              </ContextAccordionToggle>
                              <Accordion.Collapse eventKey={lightType}>
                                <Card.Body>
                                  {commonlyUsed
                                    ?.map((source, indx) => ({ ...source, index: indx }))
                                    ?.filter((source) => source?.lightType === lightType)
                                    ?.sort((a, b) =>
                                      a?.sDisplayName?.localeCompare(b.sDisplayName, undefined, {
                                        numeric: true,
                                        sensitivity: "base",
                                      })
                                    )
                                    ?.map((source) => (
                                      <CommonlySource
                                        key={source.id}
                                        source={source}
                                        index={source.index}
                                        deleteLightsFromPreference={deleteLightsFromPreference}
                                      />
                                    ))}
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion>
                        ))}
                    </div>
                  </div>
                )}
              </Droppable>
            </div>
          </div>
          <div className="text-center">
            <img alt="" src={WhelenWImg} className="img-fluid" style={{ height: "27px", marginTop: "-5%" }} />
          </div>
        </div>
      )}
      <Modal
        className="lights-modal"
        centered
        backdrop="static"
        tabIndex="-1"
        show={isOpenModal}
        onHide={closeAddLightSourceModal}
      >
        <LightModal
          setMySources={setMySources}
          closeAddLightSourceModal={closeAddLightSourceModal}
          lightsData={filteredLightData}
        />
      </Modal>
    </div>
  );
};

export default ZoneSidebarPage;
