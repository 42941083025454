/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// zone component
import React from "react";
import Select from "react-select";

import ReactTooltip from "react-tooltip";
import ZoneAImg from "../../../assets/images/zoneA.png";
import ZoneBImg from "../../../assets/images/zoneB.png";
import ZoneCImg from "../../../assets/images/zoneC.png";
import ZoneDImg from "../../../assets/images/zoneD.png";
import ZoneDetail from "./ZoneDetail";
import helper from "../../../helper/helper";
import unchecked from "../../../assets/images/white.png";
import checked from "../../../assets/images/squre-round.png";
import Footer from "../../Common/Footer";
import LowPowerComponent from "./LowPowerComponent";
import { ZONE } from "../../../services/Constant";

const ZoneContent = ({
  isGreaterThan,
  onDragEnd,
  zoneLightsData,
  deleteLightsFromZone,
  setKeysData,
  changeSystemTypes,
  systemType,
  systemTypes,
  jobName,
  setJobName,
  setDepartmentName,
  departmentName,
  vehicleName,
  setVehicleName,
  vinName,
  setVinName,
  setContractName,
  contractName,
  isOntario,
  isMirrorToZoneB,
  isMirrorToZoneD,
  mirrorToZoneD,
  mirrorToZoneB,
  performCalculation,
  isResultAvailable,
  setDirty,
  standardName,
  configName,
  onSetLowPower,
  updateLowPower,
  lowPowerOptions,
  setLowPowerZoneA,
  enableLowPowerZoneA,
  setIntensityZoneA,
  setLowPowerZoneB,
  enableLowPowerZoneB,
  setIntensityZoneB,
  setLowPowerZoneC,
  enableLowPowerZoneC,
  setIntensityZoneC,
  setLowPowerZoneD,
  enableLowPowerZoneD,
  setIntensityZoneD,
  onChangeZoneIntensity,
}) => {
  // placeholder to solve eslint error
  const handleClick = () => {};
  const onChange = () => {
    setDirty(true);
  };
  return (
    <div className="zone-content">
      <form className="zone-form px-4 px-xl-5" onChange={() => onChange()}>
        <div className="zone-form-row mx-0">
          <div className="form-group">
            <label htmlFor="systemType">System Type</label>
            <Select
              value={systemType}
              onChange={changeSystemTypes}
              options={systemTypes}
              placeholder="Please Select"
              className="select-mdd mr-25"
              autoFocus={false}
              isSearchable={false}
              styles={helper.getDropDownStyleZone(false)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="job">Job</label>
            <input
              type="text"
              className="form-control text-eclips"
              id="job"
              onChange={(e) => {
                setJobName(e.target.value);
              }}
              value={jobName}
              autoComplete="off"
              maxLength={40}
              data-tip={jobName}
              data-for="job1"
            />
            <ReactTooltip id="job1" place="right" multiline type="light" effect="solid" className="zone-tooltip" />
          </div>
          <div className="form-group">
            <label htmlFor="dept">Dept</label>
            <input
              type="text"
              className="form-control text-eclips"
              id="dept"
              onChange={(e) => {
                setDepartmentName(e.target.value);
              }}
              value={departmentName}
              autoComplete="off"
              maxLength={40}
              data-tip={departmentName}
              data-for="dept1"
            />
            <ReactTooltip id="dept1" place="right" multiline type="light" effect="solid" className="zone-tooltip" />
          </div>
          <div className="form-group">
            <label htmlFor="contract">Contract</label>
            <input
              type="text"
              className="form-control text-eclips"
              id="contract"
              onChange={(e) => {
                setContractName(e.target.value);
              }}
              value={contractName}
              autoComplete="off"
              maxLength={20}
              data-tip={contractName}
              data-for="contract1"
            />
            <ReactTooltip id="contract1" place="right" multiline type="light" effect="solid" className="zone-tooltip" />
          </div>
          <div className="form-group">
            <label htmlFor="vehicle">Vehicle</label>
            <input
              type="text"
              className="form-control text-eclips"
              id="Vehicle"
              onChange={(e) => {
                setVehicleName(e.target.value);
              }}
              value={vehicleName}
              autoComplete="off"
              maxLength={40}
              data-tip={vehicleName}
              data-for="Vehicle1"
            />
            <ReactTooltip id="Vehicle1" place="right" multiline type="light" effect="solid" className="zone-tooltip" />
          </div>
          <div className="form-group">
            <label htmlFor="vin">VIN</label>
            <input
              type="text"
              className="form-control text-eclips"
              id="VIN"
              onChange={(e) => {
                setVinName(e.target.value);
              }}
              value={vinName}
              autoComplete="off"
              maxLength={40}
              data-tip={vinName}
              data-for="Vin1"
            />
            <ReactTooltip id="Vin1" place="right" multiline type="light" effect="solid" className="zone-tooltip" />
          </div>
          <div className="form-group">
            <label htmlFor="totalcurrentdraw">Total Current Draw</label>
            <div className="avg-max">
              Peak:
              {` ${helper.getAllCurrentMax(zoneLightsData)} `}
              Average:
              {` ${helper.getAllCurrentAverage(zoneLightsData)}`}
            </div>
          </div>
        </div>
      </form>
      <div className="items-wrap px-4 px-xl-5">
        <div className="zone-items">
          <div className="zone-info">
            <div className="zone-top d-flex align-items-end justify-content-between">
              <div className="w-270">
                <h1 className="text-white text-uppercase">Zone A</h1>
                <p>FRONT OF VEHICLE</p>
                {!isOntario ? (
                  <LowPowerComponent
                    zone={ZONE.ZONE_A}
                    onSetLowPower={onSetLowPower}
                    systemType={systemType}
                    enableLowPower={enableLowPowerZoneA}
                    setLowPower={setLowPowerZoneA}
                    setIntensity={setIntensityZoneA}
                    onChangeZoneIntensity={onChangeZoneIntensity}
                    lowPowerOptions={lowPowerOptions}
                  />
                ) : null}
              </div>

              <img alt="" src={ZoneAImg} className="img-fluid" />
              <p style={{ visibility: "hidden" }}>
                <input type="checkbox" />
                Mirror To Zone C
              </p>
            </div>
            <ZoneDetail
              isGreaterThan={isGreaterThan}
              zone="A"
              onDragEnd={onDragEnd}
              zoneLightsData={zoneLightsData}
              deleteLightsFromZone={deleteLightsFromZone}
              setKeysData={setKeysData}
              isOntario={isOntario}
              isMirrorToZoneD={isMirrorToZoneD}
              isMirrorToZoneB={isMirrorToZoneB}
              standardName={standardName}
              configName={configName}
              updateLowPower={updateLowPower}
            />
          </div>
          <div className="zone-info">
            <div className="zone-top d-flex align-items-end justify-content-between">
              <div className="w-270">
                <h1 className="text-white text-uppercase">Zone B</h1>
                <p>PASSENGER SIDE (RIGHT)</p>
                {!isOntario ? (
                  <LowPowerComponent
                    zone={ZONE.ZONE_B}
                    onSetLowPower={onSetLowPower}
                    systemType={systemType}
                    enableLowPower={enableLowPowerZoneB}
                    setLowPower={setLowPowerZoneB}
                    setIntensity={setIntensityZoneB}
                    onChangeZoneIntensity={onChangeZoneIntensity}
                    lowPowerOptions={lowPowerOptions}
                    isMirrorToZoneD={isMirrorToZoneD}
                    isMirrorToZoneB={isMirrorToZoneB}
                  />
                ) : null}
              </div>

              <img alt="" src={ZoneBImg} className="img-fluid" />
              <p className="mirror-check-box">
                {isMirrorToZoneD ? (
                  <img
                    src={checked}
                    className="img-fluid cursor-pt mr-2 check-height"
                    alt=""
                    onClick={mirrorToZoneD}
                    onKeyDown={handleClick}
                  />
                ) : (
                  <img
                    src={unchecked}
                    className="img-fluid cursor-pt mr-2 check-height"
                    alt=""
                    onClick={mirrorToZoneD}
                    onKeyDown={handleClick}
                  />
                )}
                <label htmlFor="zoneB">Mirror To Zone D</label>
              </p>
            </div>
            <ZoneDetail
              isGreaterThan={isGreaterThan}
              zone="B"
              onDragEnd={onDragEnd}
              zoneLightsData={zoneLightsData}
              deleteLightsFromZone={deleteLightsFromZone}
              setKeysData={setKeysData}
              isOntario={isOntario}
              isMirrorToZoneD={isMirrorToZoneD}
              isMirrorToZoneB={isMirrorToZoneB}
              standardName={standardName}
              configName={configName}
              updateLowPower={updateLowPower}
            />
          </div>
          <div className="zone-info">
            <div className="zone-top d-flex align-items-end justify-content-between">
              <div className="w-270">
                <h1 className="text-white text-uppercase">Zone C</h1>
                <p>REAR OF VEHICLE</p>
                {!isOntario ? (
                  <LowPowerComponent
                    zone={ZONE.ZONE_C}
                    onSetLowPower={onSetLowPower}
                    systemType={systemType}
                    enableLowPower={enableLowPowerZoneC}
                    setLowPower={setLowPowerZoneC}
                    setIntensity={setIntensityZoneC}
                    onChangeZoneIntensity={onChangeZoneIntensity}
                    lowPowerOptions={lowPowerOptions}
                  />
                ) : null}
              </div>
              <img alt="" src={ZoneCImg} className="img-fluid" />
              <p style={{ visibility: "hidden" }}>
                <input type="checkbox" />
                Mirror To Zone A
              </p>
            </div>
            <ZoneDetail
              isGreaterThan={isGreaterThan}
              zone="C"
              onDragEnd={onDragEnd}
              zoneLightsData={zoneLightsData}
              deleteLightsFromZone={deleteLightsFromZone}
              setKeysData={setKeysData}
              isOntario={isOntario}
              isMirrorToZoneD={isMirrorToZoneD}
              isMirrorToZoneB={isMirrorToZoneB}
              standardName={standardName}
              configName={configName}
              updateLowPower={updateLowPower}
            />
          </div>
          <div className="zone-info">
            <div className="zone-top d-flex align-items-end justify-content-between">
              <div className="w-270">
                <h1 className="text-white text-uppercase">Zone D</h1>
                <p>DRIVER SIDE (LEFT)</p>
                {!isOntario ? (
                  <LowPowerComponent
                    zone={ZONE.ZONE_D}
                    onSetLowPower={onSetLowPower}
                    systemType={systemType}
                    enableLowPower={enableLowPowerZoneD}
                    setLowPower={setLowPowerZoneD}
                    setIntensity={setIntensityZoneD}
                    onChangeZoneIntensity={onChangeZoneIntensity}
                    lowPowerOptions={lowPowerOptions}
                    isMirrorToZoneB={isMirrorToZoneB}
                    isMirrorToZoneD={isMirrorToZoneD}
                  />
                ) : null}
              </div>

              <img alt="" src={ZoneDImg} className="img-fluid" />
              <p className="mirror-check-box">
                {isMirrorToZoneB ? (
                  <img
                    src={checked}
                    className="img-fluid cursor-pt mr-2 check-height"
                    alt=""
                    onClick={mirrorToZoneB}
                    onKeyDown={handleClick}
                  />
                ) : (
                  <img
                    src={unchecked}
                    className="img-fluid cursor-pt mr-2 check-height"
                    alt=""
                    onClick={mirrorToZoneB}
                    onKeyDown={handleClick}
                  />
                )}
                <label htmlFor="zoneD">Mirror To Zone B</label>
              </p>
            </div>
            <ZoneDetail
              isGreaterThan={isGreaterThan}
              zone="D"
              onDragEnd={onDragEnd}
              zoneLightsData={zoneLightsData}
              deleteLightsFromZone={deleteLightsFromZone}
              setKeysData={setKeysData}
              isOntario={isOntario}
              isMirrorToZoneB={isMirrorToZoneB}
              isMirrorToZoneD={isMirrorToZoneD}
              standardName={standardName}
              configName={configName}
              updateLowPower={updateLowPower}
            />
          </div>
        </div>
      </div>
      <div className="text-right px-4 px-xl-5 pt-3">
        <button
          type="button"
          disabled={isResultAvailable}
          onClick={performCalculation}
          className="btn btn-red perform-calculation text-white text-uppercase"
        >
          Perform Calculation
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default ZoneContent;
