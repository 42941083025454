// login component
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import jwt from "jwt-decode";
// Media Components
import WhelenWImg from "../../assets/images/logo.png";
import WhelenRImg from "../../assets/images/W-Red.svg";
import Helper from "../../helper/helper";
import ForgotPassword from "./ForgotPassword";
import RequestAccess from "./RequestAccess";
import ChangePassword from "./ChangePassword";
// Services
import AuthService from "../../services/AuthService";
import Footer from "../Common/Footer";

const Login = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [password, setPassword] = useState("");
  const [openModal, setModalVisibility] = useState(false);
  const [RAModal, setRAModalVisibility] = useState(false);
  const [isForceLogin, setIsForceLogin] = useState(false);
  const [token, setToken] = useState("");
  const [uid, setUid] = useState("");

  useEffect(() => {
    if (AuthService.isUserLoggedin()) {
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("loggedIn", JSON.stringify(false));
    }
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid Email Address (Format abc@xyz.com)")
      .required("Email Address is Required")
      .min(5, "Please enter valid Email Address (Format abc@xyz.com)")
      .max(128, "Please enter valid Email Address (Max 128 character allowed in format abc@xyz.com"),
    password: Yup.string().required("Password is required"),
  });

  // functions to build form returned by useForm() hook
  const { register, reset, handleSubmit, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  // used for open set-password modal
  const openSetPasswordModal = () => {
    setIsForceLogin(true);
  };

  // used for close set-password modal
  const closeSetPasswordModal = () => {
    setIsForceLogin(false);
  };
  // function for make api call for login andhandle response
  const onSubmit = (data) => {
    setIsLoading(true);
    data.password.trim();
    AuthService.login(data)
      .then((response) => {
        reset();
        setIsLoading(false);
        if (response.data.userStatus === "FORCE_CHANGE_PASSWORD") {
          setToken(response.data.token.toString());
          setUid(response.data.userId);
          openSetPasswordModal();
        } else {
          const resData = response.data;
          // decode idToken
          const decoded = jwt(resData.idToken);
          Object.keys(decoded).forEach((key) => {
            if (key.includes(":")) {
              const [, custumAttribute] = key.split(":");
              resData[custumAttribute] = decoded[key];
            } else if (key === "family_name") {
              resData.lastName = decoded.family_name;
            } else if (key === "name") {
              resData.firstName = decoded.name;
            } else if (key === "sub") {
              resData.userId = decoded.sub;
            } else {
              resData[key] = decoded[key];
            }
          });
          AuthService.setLocalStorage(resData, data);
          history.push("/home");
        }
      })
      .catch((error) => {
        Helper.displayErrorMessage(error);
        setIsLoading(false);
      });
  };

  // placeholder to solve eslint error
  const handleClick = () => {};

  // toggle the password view icon
  const toggleVisiblePassword = () => {
    setIsHidden(!isHidden);
  };

  // set the password on change
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // used for open forgot-password modal
  const openForgotPasswordModal = () => {
    setModalVisibility(true);
  };

  // used for close forgot-password modal
  const closeForgotPasswordModal = () => {
    setModalVisibility(false);
  };
  // used for open RA modal
  const openRAModal = () => {
    setRAModalVisibility(true);
  };

  // used for close RA modal
  const closeRAModal = () => {
    setRAModalVisibility(false);
  };

  return (
    <>
      <section className="theme-bg login-page flex-column">
        <div className="container" style={{ marginBottom: "1rem" }}>
          <div className="row justify-content-center">
            <div className="login-block">
              <div className="brand-logo text-center">
                <img alt="Whelen" src={WhelenRImg} className="img-fluid" />
              </div>
              <h1 className="login-title">Apparatus Outfitting Guide</h1>
              <form className="login-form" action="" method="post" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="form-group">
                  <label>Email Address</label>
                  <input
                    type="text"
                    name="email"
                    ref={register}
                    className={`form-control ${errors.email ? "is-invalid" : ""}`}
                    autoComplete="off"
                    maxLength={128}
                    style={{ "text-transform": "lowercase" }}
                  />
                  {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="pos-relative">
                    <input
                      type={isHidden ? "password" : "text"}
                      name="password"
                      ref={register}
                      className={`form-control ${errors.password ? "is-invalid" : ""}`}
                      onChange={handlePasswordChange}
                      autoComplete="off"
                      maxLength={256}
                    />
                    {password && !errors.password ? (
                      <button type="button" className="input-icon-addon custom-i-addon toggle-pwd">
                        <i
                          aria-hidden="true"
                          className={`far ${isHidden ? "fa-eye-slash" : "fa-eye"}`}
                          onClick={toggleVisiblePassword}
                          onKeyDown={handleClick}
                          role="button"
                          tabIndex={0}
                        />
                      </button>
                    ) : null}
                    {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <button type="submit" disabled={isLoading} className="btn btn-red btn-block">
                    {isLoading ? <span className="spinner-border spinner-border-sm mr-1" /> : ""}
                    Log in
                  </button>
                  <button
                    disabled={isLoading}
                    type="button"
                    className="btn btn-black"
                    onClick={openRAModal}
                    style={{ backgroundColor: "dimgrey" }}
                  >
                    REQUEST ACCESS
                  </button>
                </div>
              </form>
              <div className="login-trouble-link text-center">
                <a href="#" tabIndex="-1" className="text-white" onClick={openForgotPasswordModal}>
                  Trouble Logging In?
                </a>
              </div>
              <div className="bottom-logo mx-auto">
                <a href="https://www.whelen.com/" rel="noreferrer" target="_blank">
                  <img alt="" src={WhelenWImg} className="img-fluid" />
                </a>
              </div>
              <Modal
                className="trouble-login-modal"
                centered
                backdrop="static"
                tabIndex="-1"
                show={openModal}
                onHide={closeForgotPasswordModal}
              >
                <ForgotPassword closeForgotPasswordModal={closeForgotPasswordModal} />
              </Modal>
              <Modal centered backdrop="static" tabIndex="-1" show={RAModal} onHide={closeRAModal}>
                <RequestAccess closeRAModal={closeRAModal} />
              </Modal>
              <Modal
                className="changepassword-modal"
                centered
                backdrop="static"
                keyboard={false}
                show={isForceLogin && !!token && !!uid}
                onHide={closeSetPasswordModal}
              >
                <ChangePassword closeChangePasswordModal={closeSetPasswordModal} token={token} uid={uid} isForceLogin />
              </Modal>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};
export default Login;
