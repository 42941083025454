import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { DELETE_MSG } from "../../../services/Constant";
import config from "../../../config/config";
import Helpers from "../../../helper/helper";

const DeleteConfiguration = ({ closeDelConfigModal, delConfigData, loading, userConfigId, configName }) => {
  const { SUCCESS_MESSAGE } = config;
  const history = useHistory();
  const delConfigModal = () => {
    if (!userConfigId?.id) {
      history.push("/home");
      Helpers.displayNotification(SUCCESS_MESSAGE.DELETE_CONFIG, "success");
      return;
    }
    delConfigData(userConfigId?.id);
  };
  const location = useLocation();

  // Determine the modal type based on the current location
  const isDeleteModal = location.pathname.includes("/zone");
  const modalId = isDeleteModal ? "saveas-modal" : "delete-modal";

  return (
    <>
      <div id={modalId} className="m-auto">
        <div className="modal-dialog m-0">
          <div className="modal-content">
            <div className="close-icon text-right">
              <button type="button" className="close" data-dismiss="modal" onClick={closeDelConfigModal}>
                <span aria-hidden="true">
                  <i className="fa fa-times text-red" />
                </span>
              </button>
            </div>
            <h4 className="modal-title text-white text-uppercase"> Delete Configuration </h4>
            <div className="rotation-option-delete">
              <div className="form-group" style={{ width: "100%" }}>
                <div className="form-group">
                  <div style={{ color: "white" }}>
                    {DELETE_MSG}
                    <span className="font-weight-bold">{userConfigId?.configName || configName}</span>
                    <span>?</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                button
                type="submit"
                style={{ marginRight: "40px" }}
                className="btn btn-red"
                onClick={() => delConfigModal()}
              >
                Yes
                {loading ? <span className="spinner-border spinner-border-sm ml-2" /> : ""}
              </button>
              <button
                button
                type="submit"
                className="btn"
                style={{ backgroundColor: "#424242" }}
                onClick={() => closeDelConfigModal()}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteConfiguration;
